import { useContext } from 'react'
import { Perms, SessionContext } from '../session-context'

type AccessControlProps = {
  requiredPermission?: keyof Perms,
  requiredComponent?: string,
  children: JSX.Element
}

const AccessControl = ({ requiredPermission, requiredComponent, children }: AccessControlProps) => {
  const { user, isOn } = useContext(SessionContext)

  if (!requiredPermission && !requiredComponent) {
    return children
  }
  if (requiredPermission) {
    const perms = user?.attributes?.perms || {}
    if (!perms[requiredPermission]) {
      return <></>
    }
  }
  if (requiredComponent) {
    if (!isOn(requiredComponent)) {
      return <></>
    }
  }

  return children
}

export default AccessControl
