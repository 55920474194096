const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function setFollow ({ chatServiceUrl, followed, person_ids }) {
  const method = 'PATCH'
  const path = `https://${chatServiceUrl}/follows`
  const body = {
    data: {
      type: 'user_follows',
      attributes: {
        person_ids: person_ids,
        followed: followed
      },
      relationships: {}
    }
  }

  return fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

export { setFollow }
