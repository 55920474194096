import { getFeatureFlags } from 'api/feature_flags'
import { getIntegrationFeature } from 'api/integrations'
import { useEffect, useState, createContext } from 'react'
import { useQuery } from 'react-query'

interface ShareProviderInterface {
  crmIntegration: string,
  flags: Record<string, boolean>
}

const ShareContext = createContext<ShareProviderInterface>({
  crmIntegration: '',
  flags: {}
})

function ShareProvider ({ children }: { children?: JSX.Element | JSX.Element[] }) {
  const [crmIntegration, setCRMIntegration] = useState('')
  const [loadingCRM, setLoadingCRM] = useState(false)
  const { data: flags } = useQuery('featureFlags', async () => {
    return await getFeatureFlags()
  })

  useEffect(() => {
    if (!crmIntegration && !loadingCRM) {
      setLoadingCRM(true)
      getIntegrationFeature('crm.abe')
        .then(response => {
          // Default to none for users that don't have a crm integration
          setCRMIntegration(response?.data?.attributes?.integration?.name || 'none')
          setLoadingCRM(false)
        })
        .catch(_ => {
          setCRMIntegration('none')
          setLoadingCRM(false)
        })
    }
  }, [crmIntegration, loadingCRM])

  const providerValues: ShareProviderInterface = {
    crmIntegration,
    flags: flags || {}
  }

  return (
    <ShareContext.Provider value={providerValues}>
      {children}
    </ShareContext.Provider>
  )
}

export { ShareContext, ShareProvider }
