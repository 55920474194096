import { useDoQuery } from 'classes/useDoQuery'
import { doGet, doPatch, doPost, doDelete, doStreamFetch } from './api'
import { SearchParams, parameterizeFilters } from 'classes/queryHelpers'

export const getAccount = ({ accountID }: { accountID: number }) => {
  return doGet({ useChatURL: true, path: `/accounts/${accountID}` })
}

export const getOrg = ({ domain }: { domain: string }) => {
  return doGet({ useChatURL: true, path: `/organizations/${domain}` })
}

/**
 * @param {{ domain: string; }} param0
 */
export const getAccountByDomain = ({ domain }: { domain: string }) => {
  return doGet({ useChatURL: true, path: `/accounts/domain/${domain}` })
}

/**
 * @param {{
 * segmentId?: number
 * pageSize?: number
 * }} param0
 */
export const getAccounts = ({ segmentId, pageSize }: { segmentId: number, pageSize: number }) => {
  const params = new URLSearchParams()
  if (segmentId) {
    params.set('filter[segment_id]', segmentId.toString())
  }

  if (pageSize) {
    params.set('page[size]', pageSize.toString())
  }
  const path = '/accounts?' + params.toString()

  return doGet({ path, useChatURL: true })
}

export const updateAccount = ({ account }: any) => {
  if (account.id) {
    const id = account.id
    // done to handle strings (domains) and numbers (account ids)
    // and so Account class in CM doesn't complain
    account.id = undefined
    if (account.attributes) {
      return doPatch({ useChatURL: true, data: account, path: `/accounts/${id}` })
    }
    return doPatch({ useChatURL: true, data: { attributes: account }, path: `/accounts/${id}` })
  }
  return doPost({ useChatURL: true, data: { attributes: account }, path: '/accounts' })
}

export const syncIntegrationAccounts = () => {
  return doPost({ path: '/abm/crm_sync', data: {}, useChatURL: true })
}

export const createIntegrationAccount = ({ domain, additionalFields }: any) => {
  return doPost({ path: '/abm/accounts', data: { attributes: { domain, additionalFields } }, useChatURL: true })
}

export const getABMSettings = () => {
  return doGet({
    path: '/abm/settings',
    useChatURL: true
  })
}

export const updateABMSettings = ({ ABMSettings }: any) => {
  return doPatch({
    path: '/abm/settings',
    useChatURL: true,
    data: ABMSettings
  })
}

export const deleteAccount = ({ accountID }: { accountID: number }) => {
  return doDelete({ useChatURL: true, path: `/accounts/${accountID}` })
}

export const importFromCSV = ({ csvFile, fallbackUserID }: { csvFile: any, fallbackUserID: number }) => {
  const params = new URLSearchParams({
    fallback_user_id: String(fallbackUserID)
  })
  const path = '/accounts/csv_import?' + params.toString()
  return doPost({
    useChatURL: true,
    path,
    rawBody: csvFile
  })
}

export const exportAccounts = ({ search, segmentId }: { search: string, segmentId: number }) => {
  const data = {}
  const params = new URLSearchParams()

  if (search) {
    params.set('filter[search]', search)
  } else if (segmentId) {
    params.set('filter[segment_id]', segmentId.toString())
  }
  const path = '/accounts:export?' + params.toString()
  return doPost({ useChatURL: true, path: path, data: data })
}

export const getMyAccountsOverview = ({ admin, tenantDomain }: { admin: boolean, tenantDomain: string }) => {
  const params = new URLSearchParams()
  if (admin) {
    params.set('all_users', 'true')
  }

  if (tenantDomain) {
    params.set('tenant_domain', tenantDomain)
  }

  const path = '/reports/my_accounts_overview?' + params.toString()
  return doGet({ useChatURL: true, path })
}

export const getAccountConversationCount = ({ accountID }: { accountID: number }) => {
  return doGet({ useChatURL: true, path: `/accounts/${accountID}/conversations` })
}

export const exportCompanies = ({ searchParams }: { searchParams: SearchParams }) => {
  let path = '/companies/summary:export'
  if (searchParams) {
    path = parameterizeFilters({ searchParams, path })
  }

  return doGet({
    path,
    useChatURL: true,
    returnText: true
  })
}

export const accountSearch = ({ search, pageSize, pageNumber, sortby, ascending }: any) => {
  const params = new URLSearchParams()
  pageSize = pageSize || 200
  if (search) {
    params.set('filter[search]', search)
  }

  if (sortby) {
    if (!ascending) {
      params.set('sort', '-' + sortby)
    } else {
      params.set('sort', sortby)
    }
  }

  if (pageSize) {
    params.set('page[size]', pageSize)
  }

  if (pageNumber) {
    params.set('page[number]', pageNumber)
  }

  const path = '/accounts:search?' + params.toString()
  return doGet({ useChatURL: true, path })
}

export const getContacts = ({ accountID }: { accountID: number }) => {
  return doGet({ path: `/accounts/${accountID}/contacts`, useChatURL: true })
}

export function getRecentConversations ({ accountID }: { accountID: number }) {
  return doGet({ useChatURL: true, path: `/accounts/${accountID}/recent_conversations` })
}

export function getActivityTimeline ({ accountID, contactID, authHeader, timeFrame }: { accountID: number, contactID?: number, authHeader: Record<string, string>, timeFrame: string }): { data: any, isLoading: boolean } {
  let path = `/accounts/${accountID}/activity_timeline?time_frame=${timeFrame}`
  if (contactID) {
    path += `&contact_id=${contactID}`
  }
  return useDoQuery({
    path,
    useChatURL: true,
    extraHeaders: authHeader,
    objectClass: undefined
  })
  // return doGet({ useChatURL: true, path: `/accounts/${accountID}/activity_timeline?time_frame=${timeFrame}`, extraHeaders: authHeader })
}

export function getActivityTimelineExport ({ accountID }: { accountID: number }) {
  return doPost({ useChatURL: true, data: {}, path: `/accounts/${accountID}/activity_timeline:export` })
}

export function getAccountPieCharts () {
  return doGet({
    path: '/reports/account_pie_charts',
    useChatURL: true
  })
}

export function getAccountSummaryTimeline () {
  return doGet({ path: '/reports/account_summary_timeline', useChatURL: true })
}

export function getAccountInsights ({ domain, question }: { domain: string, question: string }) {
  const data = {
    attributes: {
      domain,
      question
    }
  }
  return doStreamFetch({ path: '/api/core/ai/account_insights', data })
}
