import Paper from 'library/page/PageSection'
import { makeStyles } from '@material-ui/core/styles'
import LinkTrackingMapping from './LinkTrackingMapping'
import TextField from '@material-ui/core/TextField'
import { useEffect, useState } from 'react'
import { EmailQueryStringSettings } from './EmailQueryStringSettings'
import { Icon, Tooltip } from 'library/materialUI'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import MQLContactCreationRules from './MQLContactCreationRules'
import IntegrationPicker from './IntegrationPicker'
import { getCustomFields } from 'api/custom_fields'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#000000BF',
    fontSize: '16px',
    marginTop: '40px',
    fontWeight: 'bold'
  },
  description: {
    color: '#00000080',
    fontSize: '14px'
  },
  customInput: {
    height: '50px',
    marginTop: '10px',
    width: '40vw'
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#343434',
    borderRadius: '5px',
    width: '50px',
    height: '50px',
    marginLeft: '10px',
    cursor: 'pointer'
  }
}))

interface EmailQueryStringContentProps {
  setCanSave: (value: boolean) => void
  settings: EmailQueryStringSettings
  dispatch: any
}

export default function EmailQueryStringContent ({ setCanSave, settings, dispatch }: EmailQueryStringContentProps) {
  const classes = useStyles()
  const [trackingString, setTrackingString] = useState('')
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Snippet copied to clipboard!'
  })
  const [customFields, setCustomFields] = useState([])

  useEffect(() => {
    getCustomFields('people').then(response => {
      setCustomFields(response.data)
    })
  }, [])

  useEffect(() => {
    let newString = ''
    settings.fieldMappings?.forEach((item, index) => {
      if (!item.signalsFieldID) {
        return
      }
      newString += item.signalsFieldID + '=' + item.emailField
      if (index !== settings.fieldMappings.length - 1) {
        newString += '&'
      }
      setTrackingString(newString)
    })
  }, [settings.fieldMappings])

  return (
    <div>
      <Paper
        title=''
        subtitle='Create contacts and track web activity when recipients of your third-party email campaigns click through your emails to your site. This will allow you to pre-fill forms with their details, tailor bots to recipients of certain email campaigns, and more.'
      >
        {' '}
      </Paper>
      <Paper
        title='Link Tracking'
        subtitle='Use link tracking to pre-fill forms by mapping key fields from your email provider to Signals.'
      >
        <div className={classes.title}>
          Mapping
        </div>
        <div className={classes.description}>
          Select and map the fields you want to be brought into Signals from your email provider. Each of the fields below will need to be contained as a merge field in your email to be brought into Signals. Learn more
        </div>
        <div>
          <LinkTrackingMapping settings={settings} dispatch={dispatch} setCanSave={setCanSave} customFields={customFields} />
        </div>
        <div className={classes.title}>
          Generate Tracking String
        </div>
        <div className={classes.description}>
          After configuring the above settings, copy the string below and add it to the end of any links in your emails.
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <TextField
              variant='outlined'
              margin='dense'
              value={trackingString}
              className={classes.customInput}
              onChange={() => null}
            />
            <div
              onClick={() => {
              navigator.clipboard.writeText(trackingString).then(
                () => {
                  setSnackState({
                    open: true,
                    variant: 'success',
                    message: 'Tracking string copied to clipboard!'
                  })
                }
              )
              }}
              className={classes.buttonStyle}
            >
              <Tooltip title='Copy to clipboard'>
                <div>
                  <Icon icon='clone' size='lg' color='white' />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </Paper>
      <Paper
        title='Intent-Qualified Lead (IQL) Creation'
        subtitle='Contacts, classified as IQLs, will be created for visitors who click on tracked links in your email and meet the criteria below.'
      >
        <div className={classes.title}>
          Contact Creation Rules
        </div>
        <div className={classes.description}>
          Configure when contacts will be created. Set stricter criteria if you want to avoid creating contacts for visitors who bounce.
        </div>
        <MQLContactCreationRules
          settings={settings}
          dispatch={dispatch}
          setCanSave={setCanSave}
        />
        <div className={classes.title}>
          Integration
        </div>
        <div className={classes.description}>
          Select an integration to send these contacts to
        </div>
        <div>
          <IntegrationPicker
            settings={settings}
            dispatch={dispatch}
            setCanSave={setCanSave}
          />
        </div>
      </Paper>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </div>
  )
}
