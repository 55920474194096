import React from 'react'
import TextField from '@material-ui/core/TextField'
import { InputAdornment } from '@material-ui/core'
import './customstyles.css'

export default function SmallTextField (props) {
  let inputProps = {}
  if (props.startAdornment) {
    inputProps = {
      startAdornment: (
        <InputAdornment position='start'>
          {props.startAdornment}
        </InputAdornment>
      )
    }
  }
  const { propertyID, autoFocusID, state, label, dispatch, required, errorMessage } = props

  return (
    <TextField
      autoFocus={autoFocusID === propertyID}
      className='small-text-field'
      value={state[propertyID]}
      onChange={e => dispatch({ property: propertyID, value: e.target.value })}
      placeholder={label}
      margin='dense'
      variant='outlined'
      style={{ margin: 0 }}
      fullWidth
      InputProps={inputProps}
      error={state[propertyID] === '' && required}
      helperText={(state[propertyID] === '' && required) ? errorMessage : ''}
    />
  )
}
