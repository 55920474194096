import { useDoQuery, useDoTypeQuery } from './useDoQuery'
import { pick } from 'lodash'
import { castToDate, DateType } from './classHelpers'
import { doPost, doPatch, doDelete } from 'api/api'
import { GenericObject } from './genericObject'
import { LoadAllProps, SearchParams } from './queryHelpers'
import Company from './companies'

interface SegmentList {
  list: Segment[]
  dict: { [id: Segment['id']]: Segment }
  categories: string[]
}
const dbProps = ['description', 'name', 'segObjectType', 'segmentType', 'triggers', 'objectIDs']
export type SegmentFilter = { filters: Filter[] }
export type Filter = { cmp: string, prop: string, value: SegmentFilterValue }
export type SegmentFilterValue = string | string[] | number | number[]
export type SegmentType = 'dynamic' | 'static'

export class Segment extends GenericObject {
  id: number
  segObjectType: string
  name: string
  segmentType: SegmentType
  createdByUserID: number
  triggers: SegmentFilter[]
  deletedTimestamp: null | DateType
  updatedTimestamp: DateType
  objectCount: number
  description: string | null
  uiVersion: string
  objectIDs: any[]
  objectType = 'segments'

  get subtitle (): string { return '' }
  get searchField (): string { return this.name }
  get isICP (): boolean { return this.name === 'ICP' }
  get ICPTitle (): string { return 'Ideal Customer Profile (ICP)' }

  static clone (segment: Segment): Segment {
    return new Segment({
      row: {
        id: segment.id,
        attributes: {
          object_type: segment.segObjectType,
          name: segment.name,
          segment_type: segment.segmentType,
          created_by_user_id: segment.createdByUserID,
          triggers: segment.triggers,
          deleted_timestamp: segment.deletedTimestamp,
          updated_timestamp: segment.updatedTimestamp,
          object_count: segment.objectCount,
          description: segment.description,
          ui_version: segment.uiVersion
        }
      }
    })
  }

  static loadAll (props?: LoadAllProps): { data: SegmentList, isLoading: boolean } {
    return useDoQuery({
      path: '/segments',
      objectClass: Segment,
      useChatURL: true,
      searchParams: props?.searchParams
    })
  }

  static loadDomainCount (ID: Segment['id']): { data: number | number[], isLoading: boolean } {
    return useDoTypeQuery({
      path: `/segments/${ID}/domain:count`,
      useChatURL: true
    })
  }

  static save (segment: Partial<Segment>, action?: string): any {
    const attributes = pick(segment, dbProps)
    if ('segObjectType' in attributes) {
      attributes.objectType = attributes.segObjectType
      delete attributes.segObjectType
    }
    if (segment.segmentType !== 'static') {
      attributes.objectIDs = []
    }
    const data = {
      type: 'segments',
      id: segment.id,
      attributes: attributes
    }
    if (segment.id) {
      let path = `/segments/${segment.id}`
      if (action) {
        path += `?method=${action}`
      }
      return doPatch({ path, data, useChatURL: true })
    } else {
      const path = '/segments'
      return doPost({ path, data, useChatURL: true })
    }
  }

  static loadOne (ID: Segment['id']): { data: Segment, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/segments/${ID}`, objectClass: Segment })
  }

  delete (): Promise<any> {
    const path = `/segments/${this.id}`
    return doDelete({ path, useChatURL: true })
  }

  static create (): void {
    window.open('/#/account_management/segments')
  }

  static edit (id: number): void {
    window.open(`/#/account_segments/${id}`)
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const attributes = row?.attributes
    this.id = row?.id
    this.segObjectType = attributes?.object_type
    this.name = attributes?.name
    this.segmentType = attributes?.segment_type
    this.createdByUserID = attributes?.created_by_user_id
    this.triggers = attributes?.triggers || []
    this.deletedTimestamp = castToDate(attributes?.deleted_timestamp)
    this.updatedTimestamp = castToDate(attributes?.updated_timestamp)
    this.objectCount = attributes?.object_count
    this.description = attributes?.description
    this.uiVersion = attributes?.ui_version
    this.objectIDs = []
    this.delete = this.delete.bind(this)
  }
}

type EvalSegmentTriggersList = {
  list: EvalSegmentTriggers[]
  dict: { [id: Company['id']]: EvalSegmentTriggers }
}
export class EvalSegmentTriggers {
  explanation: string
  company: Company

  static loadOne ({ searchParams }: { searchParams: SearchParams }): { data: EvalSegmentTriggersList, isLoading: boolean } {
    return useDoQuery({
      path: '/segment/evaluate/included',
      searchParams: searchParams,
      objectClass: EvalSegmentTriggers,
      useChatURL: true,
      initialData: { attributes: { companies_in_segment: [], companies_not_in_segment: [] } }
    })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    const attributes = row?.attributes
    this.explanation = attributes.explanation
    this.company = attributes.company
  }
}
