import { useState, useEffect, useRef } from 'react'
import { TopMenuBar, VersionHistoryModal } from './TopMenuBar'
import { getBot } from 'api/bots'
import FlowSurface from './FlowSurface'
import { Redirect } from 'react-router-dom'
import DeleteModal from 'cf-components/DeleteModal'
import Modal from 'components/Modal'
import Bot from 'classes/bots'

const FlowBuilder = props => {
  const surfaceRef = useRef(null)
  const saveRef = useRef(null)
  const dataRef = props.dataRef
  const toolkitInstanceRef = useRef(null)
  const chatServiceUrl = props.chatServiceUrl
  const [dataLoaded, setDataLoaded] = useState(false)
  const [redirect, setRedirect] = useState()
  const [versionModalOpen, setVersionModalOpen] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const [availableBot, setAvailableBot] = useState(false)
  const [unavailableBot, setUnavailableBot] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  let containerStyle = {
    width: '100%',
    height: '100%'
  }

  useEffect(() => {
    window.chatServiceUrl = chatServiceUrl
  }, [chatServiceUrl])

  useEffect(() => {
    setTimeout(() => {
      const old_flow = props.flow?.attributes?.flow
      if (old_flow?.data && !dataRef.current) {
        dataRef.current = old_flow
        toolkitInstanceRef.current.load(
          dataRef.current
        )
      } else if (!dataRef.current) {
        dataRef.current = {
          data: {
            nodes: [
              {
                id: 'start',
                type: 'start',
                ports: [
                  { id: 'default' }
                ]
              }
            ],
            edges: []
          }
        }
        toolkitInstanceRef.current.load(
          dataRef.current
        )
      }
      setDataLoaded(true)
    })
  }, [dataRef, props.flow])

  function restoreVersion (flowID) {
    setDataLoaded(false)
    toolkitInstanceRef.current.clear()
    dataRef.current = null
    setVersionModalOpen(false)
    props.restoreFlow(flowID)
  }

  function createTest () {
    if (props.botID) {
      const botName = props.values.name
      setRedirect('/bot_tests/new?name=' + botName + ` Test&type=ab&bot_id=${props.botID}`)
    } else {
      setRedirect('/bot_tests/new?name=New Test&type=ab')
    }
  }

  function handleArchive () {
    Bot.deleteBot(props.botID).then(
      setArchiveModalOpen(false),
      setRedirect('/engagement/chatbots/chatbots')
    )
  }

  function initiateSave () {
    if (props.botID) {
      const botID = props.botID
      getBot({ botID }).then(bot_response => {
        const flow_id = bot_response.data.attributes.flow_id
        if (flow_id === props.flowID) {
          saveRef.current()
        }
      })
    } else {
      saveRef.current()
    }
  }

  if (fullScreen) {
    containerStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: 'white',
      zIndex: 1300,
      height: '100vh',
      width: '100vw'
    }
  }

  if (redirect) {
    return <Redirect push to={redirect} />
  }

  window.saveParentBot = props.save

  let modalMessage = 'This bot is the default available and unavailable bot. Please update another bot to be the default before archiving.'
  if (!availableBot) {
    modalMessage = 'This bot is the default unavailable bot. Please update another bot to be the default before archiving.'
  } else if (!unavailableBot) {
    modalMessage = 'This bot is the default available bot. Please update another bot to be the default before archiving.'
  }

  return (
    <div style={{ height: 'calc(100vh - 119px)' }}>
      <div style={containerStyle}>
        <TopMenuBar
          initiateSave={initiateSave}
          publishBot={props.publishBot}
          botID={props.botID}
          disabled={props.disabled}
          saveDisabled={props.disabled || Boolean(props.activeTest)}
          showVersionHistory={() => setVersionModalOpen(true)}
          showArchiveModal={() => {
            if (!availableBot & !unavailableBot) {
              setArchiveModalOpen(true)
            } else {
              setModalOpen(true)
            }
          }}
          activeTest={props.activeTest}
          createTest={createTest}
          chatServiceUrl={chatServiceUrl}
          enabled={props.enabled}
          archived={props.archived}
          setAvailableBot={setAvailableBot}
          setUnavailableBot={setUnavailableBot}
        />
        <div style={{ height: 'calc(100% - 38px)' }}>
          <FlowSurface
            surfaceRef={surfaceRef}
            toolkitInstanceRef={toolkitInstanceRef}
            chatServiceUrl={chatServiceUrl}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            dataLoaded={dataLoaded}
            saveRef={saveRef}
            save={props.save}
            setCanSave={props.setCanSave}
          />
          <VersionHistoryModal
            open={versionModalOpen}
            onHide={() => setVersionModalOpen(false)}
            botID={props.botID}
            loadFlow={restoreVersion}
            currentFlowID={props.flowID}
          />
          <DeleteModal
            title='Archive Bot'
            confirmButtonText='Archive'
            message='Are you sure you want to archive this bot?'
            open={archiveModalOpen}
            onHide={() => setArchiveModalOpen(false)}
            confirmAction={handleArchive}
            cancelButtonText='Cancel'
          />
          <Modal
            open={modalOpen}
            onHide={() => {
              setModalOpen(false)
            }}
            title='Cannot Archive'
            saveButtonText='OK'
            onSubmit={() => {
              setModalOpen(false)
            }}
            size='xs'
          >
            <div>{modalMessage}</div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default FlowBuilder
