import { cleanFlow } from 'pages/bot/flowCleanup'
import { doPost, doGet } from './api'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

function getBotParticipant ({ participantID }) {
  if (participantID) {
    return doGet({
      path: `/participants/${participantID}?include=avatar_upload_url`,
      useChatURL: true
    })
  } else {
    return doGet({ path: '/chat_settings', useChatURL: true })
      .then(settings_response => {
        const participant_id = settings_response.data.relationships.bot_participant.data.id
        return doGet({
          path: `/participants/${participant_id}?include=avatar_upload_url`,
          useChatURL: true
        })
      })
  }
}

function botSearch ({ botIDs }) {
  const params = new URLSearchParams()
  if (botIDs) {
    botIDs.forEach((id) => {
      params.append('ids', id)
    })
  }

  return doGet({
    useChatURL: true,
    path: '/bots?' + params.toString()
  })
}

function updateBot ({ botID, chatServiceUrl, attributes }) {
  const url = `https://${chatServiceUrl}/bots/${botID}`
  return fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify({
      data: {
        attributes: attributes
      }
    })
  })
}

function deleteBots ({ bot_ids, chatServiceUrl }) {
  const url = `https://${chatServiceUrl}/bots`
  return fetch(url, {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify({
      data: {
        attributes: {
          bot_ids: bot_ids
        }
      }
    })
  })
}

function restoreArchivedBot ({ bot_id, chatServiceUrl }) {
  const url = `https://${chatServiceUrl}/bots/${bot_id}/restore`
  return fetch(url, {
    method: 'POST',
    headers: headers
  })
    .then(response => response.json())
}

function getBots (params) {
  const sequenceID = params?.sequenceID
  const path = sequenceID
    ? `/bots?sequence_id=${sequenceID}`
    : '/bots'
  return doGet({
    path,
    useChatURL: true
  })
}

function getBot ({ botID, ID }) {
  const path = `/bots/${botID || ID}`
  return doGet({ path, useChatURL: true })
}

function getFlow ({ ID, flowID }) {
  const path = `/flows/${flowID || ID}`
  return doGet({
    path,
    useChatURL: true
  }).then(response => {
    const flow = cleanFlow(response.data.attributes.flow)
    response.data.attributes.flow = flow
    return response
  })
};

function cloneBot ({ bot_id, chatServiceUrl }) {
  const headers = {
    'Content-Type': 'application/vnd.api+json'
  }
  const url = `https://${chatServiceUrl}/bots/${bot_id}:clone`
  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: {}
    })
  }).then(response => response.json())
}

function flowSearch ({ botID }) {
  const params = new URLSearchParams()
  if (botID) {
    params.set('filter[bot_id]', botID)
  }

  return doGet({
    path: '/flows:search?' + params.toString(),
    useChatURL: true
  })
}

function getBotOverview ({ botID, chatServiceUrl }) {
  const url = `https://${chatServiceUrl}/bots/${botID}/overview`
  return fetch(url, {
    method: 'GET',
    headers: headers
  }).then(response => response.json())
}

function getBotReport ({ botID, chatServiceUrl, startDate, endDate }) {
  const url = `https://${chatServiceUrl}/bots/${botID}/report?start_date=${startDate}&end_date=${endDate}`

  return fetch(url, {
    method: 'GET',
    headers: headers
  }).then(response => response.json())
}

function saveBotDetails ({ chatServiceUrl, title, description, botID }) {
  const url = `https://${chatServiceUrl}/bots/${botID}`
  const method = 'PATCH'
  const body = {
    data: {
      attributes: {}
    }
  }

  if (description) {
    body.data.attributes.description = description
  }
  if (title) {
    body.data.attributes.name = title
  }

  return fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: headers
  }).then(response => response.json())
}

export function createBot ({ name, description, channelIDs, kind, template }) {
  const path = '/bots'
  const data = {
    type: 'bots',
    attributes: {
      name: name,
      description: description,
      channel_ids: channelIDs,
      kind: kind,
      template: template
    }
  }
  return doPost({ path, data, useChatURL: true })
}

export {
  getBots,
  getBot,
  getFlow,
  deleteBots,
  botSearch,
  getBotParticipant,
  restoreArchivedBot,
  cloneBot,
  flowSearch,
  updateBot,
  getBotOverview,
  getBotReport,
  saveBotDetails
}
