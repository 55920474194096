import { doDelete, doGet, doPatch } from 'api/api';
import { sourceIconMapping } from 'cf-components/table/TableAvatars';
import { GetIntegrationMapping, IntegrationMappingType, Integrations, integrationsNames } from 'helpers/integrations';
import { castToDate, DateType } from './classHelpers';
import GenericObject from './genericObject';
import { LoadAllProps, QueryFilter } from './queryHelpers'
import { infiniteQueryResult, useDoInfiniteQuery, useDoQuery, useDoTypeQuery } from './useDoQuery';

export const contactTypeFilter = (type: 'prospect' | 'organic' | 'iql' | 'prostoorg'): QueryFilter[] => {
  const isProspect = type
  const filter: QueryFilter[] = [
    {
      field: 'contact_type',
      operator: 'eq',
      value: isProspect === 'prostoorg' ? 'prospect' : isProspect
    }
  ]
  if (type === 'prostoorg') {
    filter.push(
      {
        field: 'chats',
        operator: 'gte',
        value: 1
      }
    )
  }
  if (type === 'prospect') {
    filter.push(
      {
        field: 'chats',
        operator: 'eq',
        value: 0
      }
    )
  }
  return filter
}

export const accountContactsFilter = (domain: string): QueryFilter[] => {
  return [{ field: 'domain', operator: 'eq', value: domain }, { field: 'activity', operator: 'eq', value: 'any' }]
}
interface ContactList {
  list: Contact[]
  dict: { [id: Contact['id']]: Contact }
  meta: { total: number }
}

type InsightMeta = {
  name: string,
  id?: number
}

type InsightTypes = 'integration' | 'email'

type Insight = {
  tenant_id: number,
  contact_id: number,
  created_timestamp: DateType
  play_id: number,
  type: InsightTypes,
  meta?: InsightMeta[]
}

type ContactInsight = {
  contact_id: number,
  insights: Insight[]
}[]

interface SendToCRMProps {
  contactId: number,
  listId: number | null,
  integration: Integrations
}
export class Contact extends GenericObject {
  firstName: string
  lastName: string
  phone: string
  email: string
  title: string
  leadScore: number
  domain: string
  contactType: string
  playID: number
  companyIsIcp: boolean
  companyName: string
  insights: ContactInsight
  lastSeen: DateType
  avatarUrl: string
  visits: number
  chats: number
  meetings: number
  objectType = 'contact'
  personaId: number
  source: string
  sourceID: number

  get subtitle (): string { return this.email }
  get link (): string { return `/contacts/${this.id}` }
  get insightIntegrations (): IntegrationMappingType[] {
    const addedToIntegrationsList: IntegrationMappingType[] = []
    this.insights.forEach(contactInsight => {
      contactInsight.insights.forEach(insights => {
        if (insights.type === 'integration' && insights.meta) {
          insights.meta.forEach((meta) => {
            const name = meta.name?.toLowerCase()
            if (integrationsNames.includes(name)) {
              addedToIntegrationsList.push(GetIntegrationMapping(name, insights.created_timestamp))
            }
          })
        } else if (insights.type === 'email' && insights.meta) {
          insights.meta.forEach((meta) => {
            const name = meta.name?.trim()
            addedToIntegrationsList.push(GetIntegrationMapping('email', insights.created_timestamp, name))
          })
        }
      })
    })
    return addedToIntegrationsList
  }
  get sourceIcon (): keyof typeof sourceIconMapping {
    // if (this.contactType && this.chats > 0) {
    //   return 'prostocon'
    // }
    if (this.contactType) {
      return this.contactType
    }
    return 'organic'
  }
  static get selectedField (): string { return 'id' }

  static loadAll (props?: LoadAllProps): { data: ContactList, isLoading: boolean, error: Error } {
    return useDoQuery({
      useChatURL: true,
      path: '/v2/contacts',
      objectClass: Contact,
      searchParams: props?.searchParams,
      initialData: { data: { list: [], dict: {} } },
      keepPreviousData: true
    })
  }

  static loadCount (props?: LoadAllProps): { data: number | number[], isLoading: boolean, error: Error } {
    return useDoTypeQuery({
      useChatURL: true,
      path: '/v2/contacts:count',
      searchParams: props?.searchParams,
      keepPreviousData: true
    })
  }

  static loadAllInfinite (props: LoadAllProps): infiniteQueryResult {
    return useDoInfiniteQuery({
      useChatURL: true,
      path: '/v2/contacts',
      objectClass: Contact,
      searchParams: props?.searchParams,
      keepPreviousData: true
    })
  }

  static getName (): string {
    return 'Contacts'
  }

  static getById (ID: number): Promise<any> {
    return doGet({ path: `/contacts/${ID}`, useChatURL: true })
  }

  static sendToCRMList ({ contactId, listId, integration } : SendToCRMProps) {
    const payload = {
      attributes: {
        contactId: contactId,
        listId: listId,
        integration: integration.toLowerCase()
      }
    }
    return doPatch({ path: '/v2/contacts/send_to_crm_list', data: payload, useChatURL: true })
  }

  delete (): Promise<any> {
    const path = `/v2/contacts/${this.id}`
    return doDelete({ path: path, useChatURL: true })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const contact = row.attributes
    this.firstName = contact.first_name
    this.lastName = contact.last_name
    this.phone = contact.phone
    this.email = contact.email
    this.title = contact.title
    this.leadScore = contact.lead_score
    this.domain = contact.domain
    this.contactType = contact.contact_type
    this.playID = contact.play_id
    this.companyIsIcp = contact.company_is_icp
    this.companyName = contact.company_name || 'Unknown'
    this.insights = contact.insights
    this.lastSeen = castToDate(contact.last_seen)
    this.avatarUrl = contact.avatar_url
    this.visits = contact.visits
    this.chats = contact.chats
    this.meetings = contact.meetings
    this.personaId = contact.persona_id
    this.source = contact.source
    this.sourceID = contact.source_id
  }
}
