import GenericObject from './genericObject'
import { LoadAllProps } from './queryHelpers'
import { useDoQuery } from './useDoQuery'

interface CRMFieldList {
  list: CRMField[]
  dict: { [id: CRMField['id']]: CRMField }
}

const validFieldTypes = [
  'picklist',
  'number',
  'checkbox',
  'int',
  'double',
  'currency',
  'boolean'
]

const invalidFieldNames = [
  'Type',
  'NumberOfEmployees',
  'AnnualRevenue',
  'IsDeleted',
  'Industry'
]

// Note* CRMField.id is the fields api_name and CRMField.name is the fields label
export class CRMField extends GenericObject {
  apiName: string
  label: string
  crmType: string
  signalsType: string
  disabled: boolean
  active: any

  get type (): string { return this.crmType }
  get disabledMessage (): string { return 'This field is not supported by Signals' }

  static loadAll (props: LoadAllProps): { data: CRMFieldList, isLoading: boolean } {
    let integration = ''
    if (props?.searchParams?.extraHeaders) {
      integration = props.searchParams.extraHeaders['x-integration'] || 'salesforce'
    }
    if (!props?.searchParams?.sortColumn || integration === '') {
      return { data: { list: [], dict: {} }, isLoading: false }
    }
    const object = props.searchParams.sortColumn
    return useDoQuery({ path: `/crm_schema/${integration}/${object}/fields`, objectClass: CRMField, useChatURL: true })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const crmField = row?.attributes
    this.name = crmField.label
    this.apiName = crmField.api_name
    this.label = crmField.label
    this.crmType = crmField.crm_type
    this.signalsType = crmField.data_type
    this.disabled = !validFieldTypes.includes(this.signalsType) || invalidFieldNames.includes(crmField.api_name)
    this.active = crmField.is_active
  }
}
