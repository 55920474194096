const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const getUserFollows = ({ chatServiceUrl }) => {
  const method = 'GET'

  return fetch(`https://${chatServiceUrl}/follows`, {
    method: method,
    headers: headers
  }).then((response) => response.json())
}

export { getUserFollows }
