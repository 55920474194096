import { getTenantAPICallCount, getTenantChatSummary } from 'api/admin_only/tenants'
import { QueryFilterList } from 'classes/queryHelpers'
import { Chart } from 'library/charts/Chart'
import { SparkLineChartProps } from 'classes/report'
import { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI'
import { Card } from '@material-ui/core'
import { IconType } from 'library/materialUI/Icon'
import { getTenantROIOppSummaryReport } from 'api/reports'
import { dateHelper } from 'cf-components/DateRangeSelector'
import InformationIcon from 'library/InformationIcon'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  card: {
    marginRight: 20,
    marginBottom: 15
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    gridGap: 5,
    padding: 10
  },
  value: {
    display: 'inline-block',
    fontSize: 30,
    fontWeight: 600,
    width: '40%'
  },
  chart: {
    height: 40,
    width: '60%',
    display: 'inline-block'
  },
  valueChangeDiv: {
    textAlign: 'right'
  }
}))

interface chatSummaryParams {
  attributes: any // types need to be updated
}

interface crmSummaryParams {
  pipelineGenerated: number
  pipelineClosed: number
}

interface crmRowParams {
  Amount: number
  StageName: string
  attributes: { type: string }
}

interface summaryPropTypes {
  tenantId?: number
  userLength: number
  dateRange: {
    startDate: Date
    endDate: Date
  }
}

interface valueListTypes {
  title: string
  icon: IconType
  value: JSX.Element | string
  hasChart?: boolean
  iconColor: string
  tooltip: string
}

/* eslint-disable */
const valueList: Record<string, valueListTypes> = {
  api_calls: {
    hasChart: true, title: 'Reverse IP Lookups', icon: 'search', iconColor: '#68B7D7',
    value: <Loader />, tooltip: 'Number of Sixsense Reverse IP lookups'
  },
  pipeline_generated: { title: 'Pipeline Generated', icon: 'funnel', iconColor: '#C6ADF1', value: <Loader />, tooltip: 'Amount of pipeline generated by Signals (requires Salesforce integration)' },
  pipeline_closed: { title: 'Pipeline Closed', icon: 'dollarFunnel', iconColor: '#81AF20', value: <Loader />, tooltip: 'Amount of pipeline closed by Signals (requires Salesforce integration)' },
  user_count: { title: 'User Count', icon: 'person', iconColor: '#A2DC2B', value: <Loader />, tooltip: 'Current number of users' },
  visitors: { hasChart: true, title: 'Visitors', icon: 'pointer', iconColor: '#000000', value: <Loader />, tooltip: 'Number of visitors for the given date range' },
  page_views: { hasChart: true, title: 'Page Views', icon: 'world', iconColor: '#000000', value: <Loader />, tooltip: 'Number of page views for the given date range' },
  active_bots: { title: 'Bot Count', icon: 'botHeadAlt', iconColor: '#000000', value: <Loader />, tooltip: 'Number of bots in use by Plays' },
  prompt_count: { hasChart: true, title: 'Bot Prompts', icon: 'funnel', iconColor: '#C6ADF1', value: <Loader />, tooltip: 'Number of bot prompts for the given date range' },
  conversation_count: { hasChart: true, title: 'Conversations', icon: 'chat', iconColor: '#F3D450', value: <Loader />, tooltip: 'Number of conversations for the given date range' },
  live_chats: { hasChart: true, title: 'Live Chats', icon: 'liveChat', iconColor: '#E76033', value: <Loader />, tooltip: 'Number of live chats for the given date range' },
  meetings_booked: { hasChart: true, title: 'Meetings Booked', icon: 'dateRange', iconColor: '#954BE8', value: <Loader />, tooltip: 'Number of meetings booked for the given date range' },
  emails_captured: { hasChart: true, title: 'Emails Captured', icon: 'at', iconColor: '#4972CC', value: <Loader />, tooltip: 'Number of emails captured for the given date range' }
}
/* eslint-enable */

export const AdminTenantSummary = (props: summaryPropTypes): JSX.Element => {
  const classes = useStyles()
  const tenantId = props.tenantId
  const { dateRange } = useMemo(() => {
    return {
      dateRange: {
        startDate: dateHelper.dateToString(props.dateRange.startDate),
        endDate: dateHelper.dateToString(props.dateRange.endDate)
      }
    }
  }, [props.dateRange])
  const [chatSummary, setChatSummary] = useState<chatSummaryParams | null>(null)
  const [crmSummary, setCRMSummary] = useState<crmSummaryParams>({ pipelineGenerated: 0, pipelineClosed: 0 })
  const [apiCalls, setAPICalls] = useState(0)
  const reportProps: SparkLineChartProps = {
    object: 'tenant',
    reportType: 'sparkLineChart',
    column: 'page_views',
    category: 'period',
    timePeriod: 'day',
    admin: true,
    tenantID: tenantId,
    skipWeekends: false
  }
  const filters: QueryFilterList = [
    [
      { field: 'start_date', operator: 'gte', value: dateRange.startDate },
      { field: 'end_date', operator: 'lte', value: dateRange.endDate }
    ]
  ]
  useEffect(() => {
    if (tenantId) {
      getTenantAPICallCount({ tenantId, dateRange })
        .then(response => {
          setAPICalls(response.data.attributes.calls)
        })
    }
  }, [tenantId, dateRange])

  useEffect(() => {
    if (tenantId) {
      getTenantChatSummary({ tenantId, dateRange })
        .then(response => {
          setChatSummary(response.data)
        })
    }
  }, [tenantId, dateRange])
  useEffect(() => {
    if (tenantId) {
      getTenantROIOppSummaryReport({ tenantID: tenantId, start: dateRange.startDate, end: dateRange.endDate })
        .then(response => {
          const oppSummary = response.data.attributes.opp_summary
          const summary = {
            pipelineGenerated: 0,
            pipelineClosed: 0
          }
          // eslint-disable-next-line
          oppSummary.map((e: crmRowParams) => {
            summary.pipelineGenerated += e.Amount
            if (e.StageName === 'Closed Won') {
              summary.pipelineClosed += e.Amount
            }
          })
          setCRMSummary(summary)
        })
    }
  }, [tenantId, dateRange])

  if (chatSummary) {
    Object.keys(chatSummary.attributes).forEach(key => {
      if (valueList[key]) {
        valueList[key].value = chatSummary.attributes[key].toLocaleString('en-US')
      }
    })
  }

  if (crmSummary) {
    valueList.pipeline_generated.value = '$' + crmSummary.pipelineGenerated.toLocaleString('en-US')
    valueList.pipeline_closed.value = '$' + crmSummary.pipelineClosed.toLocaleString('en-US')
  }

  if (apiCalls) {
    valueList.api_calls.value = apiCalls.toLocaleString('en-US')
  } else {
    valueList.api_calls.value = '0'
  }
  valueList.user_count.value = props.userLength.toLocaleString('en-US')

  return (
    <div>
      {Object.keys(valueList).map((k) => {
        const v = valueList[k]
        return (
          <Card raised className={classes.card} key={k}>
            <div className={classes.cardGrid}>
              <Icon icon={v.icon} size='lg' color={v.iconColor} />
              <div>
                <div>{v.title}</div>
                <div className={classes.value}>{v.value}</div>
                {v.hasChart && (
                  <div className={classes.chart}>
                    <Chart
                      reportProps={{ ...reportProps, column: k }}
                      filters={filters}
                    />
                  </div>
                )}
              </div>
              <div className={classes.valueChangeDiv}>
                <InformationIcon message={v.tooltip} />
              </div>
            </div>
          </Card>
        )
      })}
    </div>
  )
}
