import { createSequenceFlow, getSequenceFlow, getSequenceTemplate } from 'api/sequences'
import { jsPlumbUtil } from 'jsplumbtoolkit'

function canCreateSequence (selection) {
  const nodes = selection.getNodes()
  const nodeIDs = nodes.map(n => n.id)
  let firstNode = null
  for (const node of nodes) {
    if (node.id === 'start') {
      return false
    }
    if (node.data.type === 'sequence') {
      return false
    }
    if (node.data.type === 'sequence_output') {
      return false
    }
    const incomingEdges = node.getTargetEdges()
    if (!incomingEdges.length) {
      if (firstNode && firstNode !== node) {
        return false
      } else {
        firstNode = node
      }
    }
    for (const edge of incomingEdges) {
      const parentNode = edge.source.getNode().id
      if (!nodeIDs.includes(parentNode)) {
        if (firstNode && firstNode !== node) {
          return false
        } else {
          firstNode = node
        }
      }
    }
  }

  return true
}

function addNode (toolkit, data, incomingPorts, outgoingPorts) {
  toolkit.addFactoryNode('sequence', data,
    function (newNode) {
      toolkit.batch(() => {
        for (const port of incomingPorts) {
          const id = jsPlumbUtil.uuid()
          const data = {
            id: id,
            type: 'common'
          }
          toolkit.addEdge({ source: port, target: newNode, data })
        }
        for (const p of outgoingPorts) {
          const id = jsPlumbUtil.uuid()
          const data = {
            id: id,
            type: 'common'
          }
          const source = newNode.id + '.' + p.id
          const target = p.target
          toolkit.addEdge({ source, target, data })
        }
      })
    }
  )
}

function convertToNodes ({ toolkit, sequenceFlow, sequenceNode, setSelection }) {
  const newNodes = []
  const nodes = sequenceFlow.data.nodes
  const edges = sequenceFlow.data.edges
  const originalLeft = sequenceNode.data.left
  const originalTop = sequenceNode.data.top
  const nodeTranslations = {}
  let sourceDict = {}
  let targetDict = {}
  let nodeDict = {}
  edges.forEach(edge => {
    sourceDict = { ...sourceDict, [edge.source]: edge.target }
    targetDict = { ...targetDict, [edge.target]: edge.source }
  })
  nodes.forEach(node => {
    nodeDict = { ...nodeDict, [node.id]: node }
  })
  const startingNodeID = sourceDict['start.default']
  const startingNode = nodeDict[startingNodeID]
  const baselineLeft = startingNode.left
  const baselineTop = startingNode.top
  const x = originalLeft - baselineLeft
  const y = originalTop - baselineTop

  for (let node of nodes) {
    const type = node.type
    if (type === 'start' || type === 'sequence_output') {
      continue
    }
    const id = jsPlumbUtil.uuid()
    nodeTranslations[node.id] = id
    node = { ...node, top: node.top + y, left: node.left + x, id: id }
    const newNode = toolkit.addNode(node)
    newNodes.push(newNode)
  }

  for (const edge of edges) {
    if (edge.source === 'start.default') {
      continue
    }
    if (edge.target.startsWith('output')) {
      continue
    }
    const sourceSplit = edge.source.split('.')
    const source = nodeTranslations[sourceSplit[0]] + '.' + sourceSplit[1]
    const target = nodeTranslations[edge.target]
    const id = jsPlumbUtil.uuid()
    const data = {
      id: id,
      type: 'common'
    }
    toolkit.addEdge({ source, target, data })
  }

  const sequenceNodeEdges = sequenceNode.getAllEdges()
  for (const sequenceEdge of sequenceNodeEdges) {
    const sourceID = sequenceEdge.source.id
    let source, target
    if (sequenceEdge.target.id === sequenceNode.id) {
      const sourceNode = sequenceEdge.source.getNode()
      const sourcePort = sequenceEdge.source.id
      source = sourceNode.id + '.' + sourcePort
      target = nodeTranslations[startingNodeID]
    } else if (sourceID.startsWith('output')) {
      const targetKey = 'output.' + sourceID
      target = sequenceEdge.target.id
      const sourcePortSplit = targetDict[targetKey].split('.')
      source = nodeTranslations[sourcePortSplit[0]] + '.' + sourcePortSplit[1]
    }

    toolkit.removeEdge(sequenceEdge.data.id)
    const id = jsPlumbUtil.uuid()
    const data = {
      id: id,
      type: 'common'
    }
    toolkit.addEdge({ source, target, data })
  }

  toolkit.remove(toolkit.getSelection())
  toolkit.setSelection(newNodes)
  setSelection(toolkit.getSelection())
}

function unsequence ({ toolkit, setSelection }) {
  const selection = toolkit.getSelection().getNodes()
  if (selection.length !== 1) {
    return
  }
  const sequenceNode = selection[0]
  if (sequenceNode.data.trackTemplate) {
    const sequenceID = sequenceNode.data.parentSequenceID
    getSequenceTemplate({ sequenceID })
      .then(response => {
        const sequenceFlowID = response.data.attributes.sequence_flow_id
        getSequenceFlow({ sequenceFlowID })
          .then(response => {
            const sequenceFlow = response.attributes.flow
            convertToNodes({ toolkit, sequenceFlow, sequenceNode, setSelection })
          })
      })
  } else {
    const sequenceFlowID = sequenceNode.data.sequenceFlowID
    getSequenceFlow({ sequenceFlowID })
      .then(response => {
        const sequenceFlow = response.attributes.flow
        convertToNodes({ toolkit, sequenceFlow, sequenceNode, setSelection })
      })
  }
}

function convertToSequence ({ toolkit, title, description, trackTemplate }) {
  const selection = toolkit.getSelection()
  const nodes = selection.getNodes()

  const nodeArray = []
  let edgeArray = []

  const nodeIDs = nodes.map(n => n.id)
  const nodeDict = {}

  for (const n of nodes) {
    nodeDict[n.id] = jsPlumbUtil.uuid()
  }

  let maxLeft = null
  let maxTop = null

  let firstNode = null
  const incomingPorts = []
  const outgoingPorts = []
  let output = 0

  for (const node of nodes) {
    nodeArray.push({ ...node.data, id: nodeDict[node.id] })
    if (!maxLeft) {
      maxLeft = node.data.left
      maxTop = node.data.top
    } else {
      const left = node.data.left
      const top = node.data.top
      if (left > maxLeft) { maxLeft = left }
      if (top > maxTop) { maxTop = top }
    }
    const incomingEdges = node.getTargetEdges()
    if (!incomingEdges.length) {
      if (firstNode && firstNode !== node) {
        return false
      } else {
        firstNode = node
      }
    }
    for (const edge of incomingEdges) {
      const parentNode = edge.source.getNode().id
      if (!nodeIDs.includes(parentNode)) {
        if (firstNode && firstNode !== node) {
          return false
        } else {
          firstNode = node
          incomingPorts.push(parentNode + '.' + edge.source.id)
        }
      }
    }

    const ports = node.getPorts()

    for (const p of ports) {
      const edges = p.getAllEdges()
      const formattedEdges = []
      for (const e of edges) {
        const source = nodeDict[node.id] + '.' + p.id
        const target = nodeDict[e.target.id]
        const originalTarget = e.target.id
        const id = jsPlumbUtil.uuid()
        const data = {
          id: id,
          type: 'common'
        }
        if (nodeIDs.includes(originalTarget)) {
          formattedEdges.push({ source, target, data })
        } else {
          output += 1
          const label = p.data.label ? p.data.label : 'Output' + output
          outgoingPorts.push({
            id: 'output' + output,
            target,
            label
          })
          formattedEdges.push({
            source,
            target: 'output.output' + output,
            data
          })
        }
      }
      edgeArray = edgeArray.concat(formattedEdges)
    }
  }

  const outputNode = {
    id: 'output',
    type: 'sequence_output',
    nextOutputID: outgoingPorts.length + 1,
    ports: [],
    left: maxLeft + 400,
    top: maxTop + 150
  }

  for (const outputPort of outgoingPorts) {
    outputNode.ports.push({
      id: outputPort.id,
      label: outputPort.label
    })
  }
  nodeArray.push(outputNode)
  const left = firstNode.data.left
  const top = firstNode.data.top

  const startNode = {
    id: 'start',
    top: top - 160,
    left: left,
    type: 'start',
    ports: [
      { id: 'default' }
    ]
  }

  nodeArray.push(startNode)

  const edgeID = jsPlumbUtil.uuid()
  const startData = {
    id: edgeID,
    type: 'common'
  }

  const startEdge = {
    source: 'start.default',
    target: nodeDict[firstNode.id],
    data: startData
  }

  edgeArray.push(startEdge)

  const flow = {
    data: {
      edges: edgeArray,
      nodes: nodeArray
    }
  }
  const ports = outgoingPorts.map(p => ({ id: p.id, label: p.label }))
  return createSequenceFlow({ flow, track: trackTemplate, title, description, ports })
    .then(response => {
      const data = {
        id: jsPlumbUtil.uuid().substring(0, 8),
        sequenceFlowID: response.data.id,
        title,
        description,
        ports,
        top,
        left
      }
      if (trackTemplate) {
        window.refreshSequences()
        data.trackTemplate = true
      }
      data.parentSequenceID = response.data.relationships.belongs_to.data.id
      addNode(toolkit, data, incomingPorts, outgoingPorts)
      toolkit.remove(selection)
      return true
    }).catch((err) => {
      console.error('Failed to create sequence', { err })
      return false
    })
}

export { convertToSequence, canCreateSequence, unsequence }
