import { useDoQuery } from './useDoQuery'
import { doDelete, doPost, doPatch } from 'api/api'
import { GenericObject } from './genericObject'
import { User } from './users'
import { LoadAllProps } from './queryHelpers'

export interface TeamList {
  list: Team[]
  dict: { [id: Team['id']]: Team }
}

const DB_PROPS = ['name', 'teamMemberIDs', 'availabilityType', 'online', 'offlineWhenUnavailable', 'timezone', 'availabilities'] as const

const initialValues = {
  online: false,
  offline_when_unavailable: true,
  timezone: 'US/Eastern',
  availabilityType: 'manual',
  availabilities: []

}

export class Team extends GenericObject {
  ownerUserID: number
  /**
   * Only used when availability type === 'manual'
   *
   * Use this.isAvailable to get availability
   */
  online: boolean
  /** Based off their availability */
  private isCurrentlyAvailable: boolean
  offlineWhenUnavailable: boolean
  timezone: string
  teamMemberIDs: number[]
  availabilityType: 'manual' | 'scheduled'
  availabilities: {
    days: string
    start_time: string
    end_time: string
  }[]
  createdByUserID: number

  get link (): string { return `/teams/${this.id}` }
  get subtitle (): string { return `${this.teamMemberIDs.length} Members` }
  get searchField (): string { return this.name }
  get isAvailable (): boolean {
    if (this.availabilityType === 'manual') {
      return this.online
    } else {
      return this.isCurrentlyAvailable
    }
  }

  static loadAll (props?: LoadAllProps): { data: TeamList, isLoading: boolean, isError: boolean } {
    return useDoQuery({
      path: '/teams',
      objectClass: Team,
      useChatURL: true,
      initialData: { list: [], dict: {} } as TeamList,
      searchParams: props?.searchParams
    })
  }

  static loadOne (id: Team['id']): { data: Team } {
    return useDoQuery({ path: `/teams/${id}`, useChatURL: true, objectClass: Team })
  }

  static save (team: Team, dbProps: readonly typeof DB_PROPS[number][] = DB_PROPS): Promise<any> {
    const attributes = dbProps.reduce((acc, cur) => {
      switch (cur) {
        case 'offlineWhenUnavailable': return { ...acc, offline_when_unavailable: team[cur] }
        case 'teamMemberIDs': return { ...acc, user_ids: team[cur] }
        case 'timezone': return { ...acc, timezone: team[cur] || 'US/Eastern' }
        case 'availabilityType': return { ...acc, availability_type: team[cur] || 'manual' }
        case 'availabilities': return {
          ...acc,
          availabilities: team[cur]?.map(v => ({
            days: v.days,
            start_time: v.start_time,
            end_time: v.end_time
          })) || []
        }
        default: return { ...acc, [cur]: team[cur] }
      }
    }, {} as Record<string, any>)

    if (attributes?.user_ids.length === 0) {
      delete attributes.user_ids
    }

    const data = {
      type: 'teams',
      attributes
    }
    if (team.id) {
      return doPatch({ path: `/teams/${team.id}`, useChatURL: true, data })
    } else {
      return doPost({ path: '/teams', useChatURL: true, data })
    }
  }

  static deleteTeamMember ({ teamID, teamMemberID }: { teamID: Team['id'] | undefined, teamMemberID: number }) {
    const data = {
      attributes: {
        team_member_id: teamMemberID
      }
    }

    return doDelete({ path: `/teams/${teamID}/relationships/teammembers`, data: data, useChatURL: true })
  }

  static create (): void {
    window.open('/#/user_management/teams')
  }

  static edit (id: number): void {
    window.open(`/#/teams/${id}`)
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    const team = row?.attributes || initialValues
    this.ownerUserID = team.owner_user_id
    this.availabilityType = team.availability_type
    this.isCurrentlyAvailable = team.is_currently_available
    this.availabilities = ((team.availabilities || []) as Record<string, any>[]).map(a => ({
      days: a.days,
      start_time: a.start_time,
      end_time: a.end_time
    }))
    this.createdByUserID = team.created_by_user_id
    this.online = team.online
    this.offlineWhenUnavailable = team.offline_when_unavailable
    this.timezone = team.timezone
    this.teamMemberIDs = row?.relationships?.teammembers?.data.map((u: User) => u.id) || []
  }

  delete (): Promise<any> {
    return doDelete({ path: `/teams/${this.id}`, useChatURL: true })
  }
}

export default Team
