import { useRef, useReducer, useEffect, createContext } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { batchUpdateReviewStatus } from 'api/conversations'
import { useQuery } from 'react-query'
import { getRoutes } from 'api/conversation_routes'
import { getLiveView } from 'api/contacts'

/**
 * @typedef {Object} ReducerState
 * @property {any[] | null} conversationList
 * @property {Record<string, any>} conversationListMeta
 * @property {any[]} selectedConversations
 * @property {Record<string, any>} readReceipts
 * @property {any[]} onlineVisitors
 */
/**
 * @type {React.Context<{
 * state: ReducerState;
 * dispatch: (state: ReducerState, action: { type: string; value: any; }) => ReducerState;
 * clearSelection: () => void;
 * deleteFromList: (conversationID: number) => void;
 * selectConversation: (conversationID: number) => void;
 * setReviewedStatus: ({ conversationID: number, reviewed: boolean }) => void;
 * refreshRoutes: (options?: ReturnType<useQuery>['refetch'] | undefined) => Promise<any>;
 * pendingRoutes: any[];
 * clearSelectedConversation: () => void
 * isOnline: (conversation: any) => boolean
 * }>}
 */
const ConversationWindowContext = createContext({})

/** @type {ReducerState} */
const initialState = {
  conversationList: null,
  conversationListMeta: {},
  selectedConversations: [],
  readReceipts: {},
  onlineVisitors: []
}

function ConversationWindowProvider (props) {
  const history = useHistory()
  const activeIDRef = useRef(null)
  const chatServiceUrl = window.chatServiceUrl
  const convoMatch = useRouteMatch('/chat/:conversationId')
  const matchID = convoMatch?.params?.conversationId
  const activeConversationID = matchID ? parseInt(matchID) : null
  activeIDRef.current = activeConversationID

  /** @type {(state: ReducerState, action: { type: string; value: any; }) => ReducerState} */
  const reducer = (state, action) => {
    switch (action.type) {
      case 'read': {
        const receipts = state.readReceipts
        receipts[action.value] = true
        return { ...state, readReceipts: receipts }
      }
      case 'appendEvent': {
        const newList = [...state.conversationList]
        const body = action.value.attributes.body
        const receipts = state.readReceipts
        const conversationID = action.value.relationships.conversation.data.id
        const index = newList.map(c => c.id).indexOf(conversationID)
        if (index > -1) {
          const conversation = (newList.splice(index, 1))[0]
          const timestamp = (new Date()).toISOString()
          conversation.attributes.last_message = body
          conversation.attributes.last_message_timestamp = timestamp
          newList.unshift(conversation)
          receipts[conversationID] = false
        }
        return { ...state, conversationList: newList, readReceipts: receipts }
      }
      case 'appendTyping': {
        const conversationEvent = action.value
        const newList = [...state.conversationList]
        const conversationID = conversationEvent.relationships.conversation.data.id
        const participantID = conversationEvent.relationships.from_participant.data.id
        const lastParticipantEvent = { conversation_id: conversationID, created_timestamp: conversationEvent.attributes.created_timestamp, kind: conversationEvent.attributes.kind, participant_id: participantID, participant_name: conversationEvent.attributes.participant_name }
        const index = newList.map(c => c.id).indexOf(conversationID)
        if (index > -1) {
          const participantIndex = newList[index].relationships.last_participant_events.data.map(p => p.participant_id).indexOf(participantID)
          if (participantIndex <= -1) {
            newList[index].relationships.last_participant_events.data.push(lastParticipantEvent)
          }
        }
        return { ...state, conversationList: newList }
      }
      case 'removeTyping': {
        const conversationEvent = action.value
        const newList = [...state.conversationList]
        const conversationID = conversationEvent.relationships.conversation.data.id
        const participantID = conversationEvent.relationships.from_participant.data.id
        const index = newList.map(c => c.id).indexOf(conversationID)
        if (index > -1) {
          const eventsList = newList[index].relationships.last_participant_events.data
          newList[index].relationships.last_participant_events.data = eventsList.filter(p => p.participant_id !== participantID)
        }
        return { ...state, conversationList: newList }
      }
      case 'removeFromSelection': {
        const length = state.selectedConversations.length
        if (length === 1) {
          history.push({ pathname: '/chat', state: {} })
          return { ...state, selectedConversations: [] }
        }
        const remainingSelection = [...state.selectedConversations.filter(c => c.id !== action.value)]
        if (remainingSelection.length === 1) {
          history.push({ pathname: `/chat/${remainingSelection[0].id}`, state: {} })
        }
        return { ...state, selectedConversations: remainingSelection }
      }
      case 'addToSelection': {
        const currentLength = state.selectedConversations.length
        const incomingLength = action.value.length
        const conversationID = (incomingLength === 1 && !currentLength) ? action.value[0].id : null
        const pathname = conversationID ? `/chat/${conversationID}` : '/chat'
        const newList = state.selectedConversations.concat(action.value.filter(item => !state.selectedConversations.some(convo => convo.id === item.id)))
        history.push({ pathname: pathname, state: {} })
        return { ...state, selectedConversations: newList }
      }
      case 'selectConversation':
        history.push({ pathname: `/chat/${action.value}`, state: {} })
        return { ...state, selectedConversations: [] }
      case 'clearSelection':
        return { ...state, selectedConversations: [] }
      case 'refreshList':
        return {
          ...state,
          conversationList: action.value,
          conversationListMeta: action.meta,
          readReceipts: action.readReceipts
        }
      case 'deleteConversation': {
        const ids = state.conversationList.map(c => c.id)
        const index = ids.indexOf(action.value)
        const newState = { ...state }
        if (ids.length > 1) {
          const nextIndex = ids.length > index + 1 ? index + 1 : index - 1
          const nextID = ids[nextIndex]
          history.push({ pathname: `/chat/${nextID}`, state: {} })
          newState.conversationList = state.conversationList.filter(c => c.id !== action.value)
        } else {
          history.push({ pathname: '/chat', state: {} })
          newState.conversationList = []
        }
        return newState
      }
      case 'markReviewStatus': {
        const index = state.conversationList.findIndex(c => c.id === action.value.id)
        const list = [...state.conversationList]
        const conversation = { ...list[index] }
        if (action.value.reviewed) {
          conversation.attributes.reviewed_timestamp = new Date()
        } else {
          conversation.attributes.reviewed_timestamp = null
        }
        list[index] = conversation
        return {
          ...state,
          conversationList: list
        }
      }
      case 'live': {
        return { ...state, onlineVisitors: action.value }
      }
      default:
        break
    }
  }

  const {
    data: pendingRoutes = [],
    refetch: refreshRoutes
  } = useQuery('quick-draw-routes',
    () => getRoutes({ chatServiceUrl }).then(response => {
      return response.data
    }),
    { staleTime: 15000 }
  )

  const [state, dispatch] = useReducer(reducer, initialState)

  const clearSelection = () => dispatch({ type: 'clearSelection' })
  const selectConversation = conversationID => dispatch({ type: 'selectConversation', value: conversationID })
  const deleteFromList = conversationID => dispatch({ type: 'deleteConversation', value: conversationID })
  const clearSelectedConversation = () => history.push('/chat/')
  const setReviewedStatus = ({ conversationID, reviewed }) => {
    const conversationIDs = [conversationID]
    batchUpdateReviewStatus({ reviewed, conversationIDs })
      .then(() => dispatch({ type: 'markReviewStatus', value: { id: conversationID, reviewed: reviewed } }))
  }

  useEffect(() => {
    const fetchLiveView = () => {
      getLiveView()
        .then(response => {
          dispatch({ type: 'live', value: response.data })
        })
      }
      fetchLiveView()
      const interval = setInterval(fetchLiveView, 10000)
      return () => clearInterval(interval)
  }, [])

  const isOnline = (conversation) => {
    let onlineVisitor = null
    if (conversation?.relationships?.for_contact?.data?.id) {
      onlineVisitor = state.onlineVisitors.find(v => v.attributes.person_id === conversation.relationships.for_contact.data.id)
    }
    if (!onlineVisitor && conversation?.relationships?.for_participant?.data?.id) {
      onlineVisitor = state.onlineVisitors.find(v => v.attributes.participant_id === conversation.relationships.for_participant.data.id)
    }
    if (onlineVisitor) {
      return true
    }
    return false
  }

  return (
    <ConversationWindowContext.Provider
      value={{
        state: { ...state, activeConversationID },
        dispatch,
        clearSelection,
        deleteFromList,
        selectConversation,
        setReviewedStatus,
        pendingRoutes,
        refreshRoutes,
        clearSelectedConversation,
        isOnline
      }}
    >
      {props.children}
    </ConversationWindowContext.Provider>
  )
}

export { ConversationWindowContext, ConversationWindowProvider }
