import AlertsList from './Alerts/AlertsList'
import EditAlertModal from './Alerts/EditAlertModal'

const AdminAlertManagement = props => {
  return (
    <>
      <AlertsList />
      <EditAlertModal
        open={props.alertModalOpen}
        onHide={props.onHide}
        domain={props.domain}
        isGlobal
      />
    </>
  )
}

export default AdminAlertManagement
