import { doPost, doDelete, doGet, doPatch } from '../api'

export function notificationsExport () {
  const path = '/api/notifications:export'
  return doGet({ path })
}

export function getTenants () {
  const path = '/api/admin_portal/tenants'
  return doGet({ path })
}

export function saveTenant ({ tenantID, attributes }) {
  const body = {
    id: parseInt(tenantID),
    type: 'tenants',
    attributes: attributes,
    relationships: {}
  }
  const path = `/api/admin_portal/tenants/${tenantID}`
  return doPatch({ path, data: body })
}

export function saveTenantDomains ({ tenantID, attributes }) {
  const body = {
    id: parseInt(tenantID),
    type: 'tenants',
    attributes: attributes,
    relationships: {}
  }
  const path = `/admin_portal/tenants/${tenantID}/domains`
  return doPatch({ path, useChatURL: true, data: body })
}

export function getTenantChecklist (tenantId) {
  const path = `/admin_portal/tenants/${tenantId}/checklist`
  return doGet({ path, useChatURL: true })
}

export function getTenantSummary (tenantId) {
  const path = `/api/admin_portal/tenants/${tenantId}`
  return doGet({ path })
}

export function getTenantDomains (tenantId) {
  const path = `/admin_portal/tenants/${tenantId}/domains`
  return doGet({ path, useChatURL: true })
}

export function getRecentLogins (tenantId) {
  const path = `/api/admin_portal/recent_logins/${tenantId}`
  return doGet({ path })
}

export function getTenantChatSummary ({ tenantId, dateRange }) {
  // this needs to be upgraded to handle more than chat-a
  const path = `/admin_portal/tenants/${tenantId}/metrics?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`
  return doGet({ path, useChatURL: true })
}

export function getTenantSalesforceID (tenantId) {
  const path = `/admin_portal/tenants/${tenantId}/salesforce`
  return doGet({ path, useChatURL: true })
}

export function saveTenantSalesforceID ({ tenantID, accountID }) {
  const path = `/admin_portal/tenants/${tenantID}/salesforce`
  const salesforceID = {
    attributes: {
      accountID: accountID
    }
  }
  return doPatch({ path, useChatURL: true, data: salesforceID })
}

export function getTenantConsultingHours (tenantId) {
  const path = `/admin_portal/tenants/${tenantId}/consulting_hours`
  return doGet({ path, useChatURL: true })
}

export function updateTenantConsultingHours ({ tenantId, hours }) {
  const path = `/admin_portal/tenants/${tenantId}/consulting_hours`
  const consulting = {
    attributes: {
      consulting: hours
    }
  }
  return doPatch({ path, useChatURL: true, data: consulting })
}

export function getTenantRecentConversations () {
  const path = '/admin_portal/tenants/metrics'
  return doGet({ path, useChatURL: true })
}

export function getTenantAPICallCount ({ tenantId, dateRange }) {
  const path = `/admin_portal/tenants/${tenantId}/api_calls?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`
  return doGet({ path, useChatURL: true })
}

export function deleteTenant (tenantID) {
  const path = '/api/admin_portal/tenants'
  return doDelete({
    path,
    data: {
      attributes: {
        tenant_ids: [tenantID]
      }
    }
  })
}

export function getUsers (tenantId) {
  const path = `/api/admin_portal/users?tenant_id=${tenantId}`
  return doGet({ path })
}

export function getUserInvites (tenantID) {
  const path = `/api/admin_portal/user_invites?tenant_id=${tenantID}`
  return doGet({ path })
}

export function resendUserInvite (inviteID) {
  const path = `/api/admin_portal/user_invites/${inviteID}/resend`
  return doPost({ path })
}

export function resendVerificationEmail (email) {
  return doPost({
    path: '/api/admin_portal/users/email_verification:resend',
    data: {
      attributes: {
        email: email
      }
    }
  })
}

export function getUserChatStats ({ tenantId }) {
  const path = `/admin_portal/tenants/${tenantId}/user_metrics`
  return doGet({ path, useChatURL: true })
}

export function assistUser (tenantID, userID) {
  const path = '/api/admin_portal/assists'
  return doPost({
    path,
    data: {
      attributes: {
        tenant_id: parseInt(tenantID),
        user_id: userID
      }
    }
  })
}

/**
 * Get a list of tenant ids that include users matching the search text
 *
 * - email - Include user's email in user search or not *Default true*
 * - name - Include user's name in user search or not *Default true*
 *
 * @param {{
 * search: string
 * email?: boolean
 * name?: boolean
 * }} param0
 * @returns {Promise<{ data: number[]; ok: boolean; }>}
 */
export function getTenantIdsByUserSearch ({ search, email = true, name = true }) {
  const path = '/api/admin_portal/tenants/by_users:search'
  const params = new URLSearchParams({ 'filter[search]': search })

  const includeFields = []
  if (email) includeFields.push('email')
  if (name) includeFields.push('name')

  if (includeFields.length) {
    params.append('filter[fields]', includeFields.join(','))
  }

  return doGet({ path: path + '?' + params.toString() })
}
