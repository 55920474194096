import { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { NotificationContext } from 'notification-context'
import Divider from '@material-ui/core/Divider'
import ActionsMenu from 'cf-components/ActionsMenu'
import TimeSinceCalculator from 'cf-components/TimeSinceCalculator'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import SettingsIcon from '@material-ui/icons/Settings'
import { Link } from 'react-router-dom'
import { Tooltip } from 'library/materialUI'
import { Notification } from 'classes/notifications'
import { Icon, IconType } from 'library/materialUI/Icon'

const useStyles = makeStyles(theme => ({
  NotificationsCenter: {
    height: '100%',
    backgroundColor: 'white',
    fontFamily: 'Poppins, sans serif',
    display: 'grid',
    gridTemplateRows: '68px calc(100% - 68px)'
  },
  circle: {
    borderRadius: '50%',
    background: '#985CE6',
    width: 10,
    height: 10,
    margin: '30px 20px 15px 15px'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 10px 25px',
    color: 'white',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 600
  },
  notificationIcon: {
    height: 22,
    marginTop: 24,
    cursor: 'pointer'
  },
  notificationMessage: {
    width: 230,
    textAlign: 'left',
    font: 'normal normal normal 15px Poppins',
    letterSpacing: 0,
    color: '#2E2E2E',
    textDecoration: 'none',
    marginLeft: 15,
    marginRight: 5
  },
  notificationTitle: {
    cursor: 'pointer',
    paddingTop: 15
  },
  notificationCard: {
    minHeight: 70,
    textDecoration: 'none',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 7fr 1fr',
    paddingBottom: 8
  },
  notificationBody: {
    fontSize: '.9rem',
    color: '#707070',
    '& *': {
      marginBlock: '0.45rem'
    },
    '& > img': {
      maxWidth: '100%'
    }
  },
  actionsMenu: {
    marginTop: 30,
    marginRight: 15
  },
  seeAll: {
    textAlign: 'right',
    font: 'normal normal 600 12px/17px Poppins',
    letterSpacing: 0,
    color: '#985CE6',
    opacity: 1,
    cursor: 'pointer',
    marginLeft: 265,
    paddingTop: 5
  }
}))

const icons: Record<string, IconType> = {
  routes: 'route',
  meetings: 'dateRange',
  alerts: 'chatAlert',
  calendars: 'calendar',
  notes: 'note',
  followees: 'followee',
  slack: 'slack',
  teams: 'teams',
  featureAnnouncement: 'megaPhone',
  botTests: 'beaker'
}

interface NotificationCardsProps {
  row: Notification
  click: (notification: Notification) => void
  dismiss: Function
  index: number
}

function NotificationsCard (props: NotificationCardsProps) {
  const classes = useStyles()

  const title: string = props.row.title
  const body: string = props.row.body
  const type = props.row.notificationType
  const resolved = !!props.row.resolvedTimestamp
  const dismissed = !!props.row.dismissedTimestamp
  const showAsNew = resolved || dismissed
  const icon = icons[type] || 'chatAlert'

  const handleClick = () => props.click(props.row)

  const actions = [{ name: 'Dismiss', action: () => props.dismiss(props.row.id), icon: DeleteIcon }]

  return (
    <div>
      <div className={classes.notificationCard}>
        <div className={classes.circle} style={showAsNew ? { background: 'transparent' } : undefined} />
        <div className={classes.notificationIcon}>
          <Icon icon={icon} onClick={handleClick} color='#c6adf1' />
        </div>
        <div className={classes.notificationMessage}>
          <div className={classes.notificationTitle} onClick={handleClick}>
            {title}
          </div>
          {body && type !== 'meetings' && (
            <div
              className={classes.notificationBody}
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: body }}
            />
          )}
          {body && type === 'meetings' && (
            <div className={classes.notificationBody}>
              {body}
            </div>
          )}
          <TimeSinceCalculator
            notificationTime={props.row.createdTimestamp}
          />
        </div>
        {!dismissed && (
          <ActionsMenu
            actions={actions}
            className={classes.actionsMenu}
          />
        )}
      </div>
    </div>
  )
}

interface NotificationCenterProps {
  close: () => void
}

function NotificationsCenter (props: NotificationCenterProps) {
  const classes = useStyles()
  const { newNotifications, notifications, dismiss, resolve } = useContext(NotificationContext)

  const handleClick = (row: Notification) => {
    if (row.resolvedTimestamp === null) {
      resolve(row.id as number)
    }
    if (row.actionLink && row.actionLink.length) {
      window.open(row.actionLink, '_self')
    }
  }

  const oldNotifications = notifications?.filter((e: Notification) => {
    const eDate = new Date(e.createdTimestamp as Date)
    const age = Date.now() - eDate.getTime()
    if (age > 1000 * 3600 * 24 * 7) { // don't show older than 7 days
      return false
    }
    let duplicate = false
    newNotifications.map((notification) => {
      if (notification.id === e.id) {
        duplicate = true
      }
      return null
    })
    return !duplicate
  })

  return (
    <div className={classes.NotificationsCenter}>
      <div className={classes.header}>
        <div>
          Funnel Center
        </div>
        <div>
          <Tooltip title='Notification Settings' placement='bottom'>
            <IconButton onClick={() => props.close()} style={{ paddingRight: 8 }}>
              <Link to='/settings/my_settings/notifications'>
                <SettingsIcon style={{ fill: 'white', paddingTop: 5 }} />
              </Link>
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => props.close()} style={{ paddingLeft: 8 }}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        </div>
      </div>
      <div>
        <div>
          <div style={{ display: 'flex', paddingLeft: 10, paddingTop: 7, paddingBottom: 7, backgroundColor: '#F7F7F7' }}>
            {newNotifications.length ? (
              <div>
                New
              </div>
            ) : (
              <div>
                Earlier
              </div>
            )}
            <div className={classes.seeAll} onClick={() => window.open('/#/settings/my_settings/notifications?history=1', '_blank')}>
              See All
            </div>
          </div>
          <Divider />
          <div>
            {notifications.length === 0 ? (
              <div style={{ textAlign: 'center', margin: 15 }}>
                <CheckCircleOutlineIcon style={{ color: '#985CE6' }} fontSize='large' />
                <div style={{ fontSize: 'large', fontWeight: 'bold', margin: 7 }}>
                  You're all caught up!
                </div>
                You don't have any notifications for the last 7 days. Click the 'See All' link above to see your older notifications.
              </div>
            ) : (
              <div>
                {newNotifications?.map((row, index) => (
                  <div key={row.id}>
                    <NotificationsCard
                      index={index}
                      row={row}
                      dismiss={dismiss}
                      click={handleClick}
                    />
                    <Divider />
                  </div>
                ))}
              </div>
            )}
          </div>
          {newNotifications.length ? (
            <div>
              <div style={{ paddingTop: 10, paddingBottom: 7, paddingLeft: 10, backgroundColor: '#F7F7F7' }}>
                Earlier
              </div>
              <Divider />
            </div>
          ) : (
            <></>
          )}
          <div>
            {oldNotifications?.map((row, index) => (
              <div key={row.id}>
                <NotificationsCard
                  index={index}
                  row={row}
                  dismiss={dismiss}
                  click={handleClick}
                />
                <Divider />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationsCenter
