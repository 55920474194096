import { useDoQuery } from './useDoQuery'
import { GenericObject } from './genericObject'
import { LoadAllProps } from './queryHelpers'

interface ObjectDependencyList {
  list: ObjectDependency[]
  dict: { [id: ObjectDependency['parentID']]: ObjectDependency }
}
export type ObjectDependencyNodeTypes = typeof nodeTypes[number]
const nodeTypes = ['Calendar Invite'] as const

type ChildLocation = {
  id: string,
  type: ObjectDependencyNodeTypes,
  name: string
}

type ObjectDependencyParentTypes = 'bot'
export type ObjectDependencyChildTypes = typeof objectDependencyChildTypesList[number]
export const objectDependencyChildTypesList = ['meeting', 'email', 'user'] as const

export class ObjectDependency extends GenericObject {
  parentType: ObjectDependencyParentTypes
  parentID: string
  childType: ObjectDependencyChildTypes
  childID: string[]
  meta: ChildLocation[]

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const objectDependency = row?.attributes
    this.parentType = objectDependency.parent_type
    this.parentID = objectDependency.parent_id
    this.childType = objectDependency.child_type
    this.childID = objectDependency.child_id
    this.meta = objectDependency.meta
  }

  static loadAll (props: LoadAllProps): { data: ObjectDependencyList, isLoading: boolean, error: Error } {
    return useDoQuery({ path: '/object_dependencies', useChatURL: true, objectClass: ObjectDependency, searchParams: props?.searchParams })
  }
}
