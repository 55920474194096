import Loader from 'library/loading/Loader'
import { useEffect, useState } from 'react'
import { AppendFilters, parameterizeFilters, SearchParams } from 'classes/queryHelpers'
import { Emitter, EVENT_TYPE } from 'emitter'
import { CSVDownload } from 'classes/csvdownload'
import DateFormat from 'components/DateFormat'
import { downloadCSV } from 'helpers/csv'
import { SignalsScoreHistory } from 'cf-components/SignalsScoreHistory'

interface AccountListTableProps {
  handleAlert: (domain: string) => void
}

const mywindow = window as any
const exportCSV = async (searchParams: SearchParams) => {
  const exportClass = 'Account'
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true })
  const resp = await CSVDownload.download({ type: exportClass, searchParams })
  Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false })
  const date = new Date().toString()
  const csvType = exportClass.toLowerCase() + 's'
  const filename = 'signals_' + csvType + '_' + DateFormat({ dateToFormat: date, format: 'isoDate' }) + '.csv'
  downloadCSV(filename, resp)
}

export default function AccountListTable ({ handleAlert }: AccountListTableProps) {
  const searchParams: SearchParams = {
    sortColumn: 'name',
    sortOrder: 'desc',
    pageSize: 100,
    filters: []
  }
  const [currDomain, setCurrDomain] = useState('')
  const [scoreAnchorEl, setScoreAnchorEl] = useState<HTMLElement | null>(null)
  const [historyOpen, setHistoryOpen] = useState(true)
  const [currScore, setCurrScore] = useState(0)

  const tableEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const action = e.detail.action
    const ids: Array<string> = []
    if (tableID === 'accounts-body') {
      switch (action) {
        case 'export':
          Array.from(document.getElementsByClassName('rowcheckbox')).forEach((checkbox: any) => {
            if (checkbox.checked) {
              ids.push(checkbox.value)
            }
          })
          if (ids.length === 0) {
            exportCSV(searchParams)
          } else {
            const exportParams = { ...searchParams, filters: AppendFilters(searchParams.filters || [], [[{ field: 'id', value: ids, operator: 'in' }]]) }
            exportCSV(exportParams)
          }
          break
        default:
          console.warn('Unknown table action', action)
      }
    }
  }
  const rowEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const rowAction = e.detail.action
    const rowID = e.detail.rowID
    const anchorDiv = document.getElementById(e.detail.anchor)
    if (tableID === 'accounts-body') {
      switch (rowAction) {
        case 'alert':
          handleAlert(rowID)
          break
        case 'scoreHoverCancel':
          setHistoryOpen(false)
          break
        case 'scoreHover':
          if (anchorDiv) {
            setScoreAnchorEl(anchorDiv)
            setCurrDomain(rowID)
            setHistoryOpen(true)
            setCurrScore(e.detail.score)
          }
          break
        default:
          console.warn('Unknown row action', rowAction)
      }
    }
  }

  useEffect(() => {
    try {
      /*
      mywindow.htmx.logger = function (elt: any, event: any, data: any) {
        if (console) {
          console.log('INFO:', event, elt, data)
        }
      }
      */
      const ids = ['htmx-table']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
        mywindow._hyperscript.processNode(d)
      }
      mywindow.addEventListener('tableEvent', tableEventListener)
      mywindow.addEventListener('rowEvent', rowEventListener)
    } catch (e) {
      console.log(e)
    }
    return () => {
      mywindow.removeEventListener('tableEvent', tableEventListener)
      mywindow.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [currDomain])

  const url = parameterizeFilters({ path: '/api/core/web/accounts', searchParams })

  return (
    <>
      <div id='htmx-table' style={{ backgroundColor: 'white' }}>
        <div
          id='accounts'
          hx-get={url}
          hx-trigger='revealed'
          hx-indicator='.accounts-loader'
        >
          <div style={{ margin: 300 }}>
            <Loader size='xlg' />
          </div>
        </div>
      </div>
      {currDomain && (
        <SignalsScoreHistory
          score={currScore}
          placement='left'
          domain={currDomain}
          anchorEl={scoreAnchorEl}
          open={historyOpen}
        />
      )}
    </>
  )
}

// import { Actions, Fields } from 'cf-components/table/Table';
// import TableList from 'cf-components/table/TableList';
// import { Account } from 'classes/classes';
// import { TableProvider } from 'library/table/TableProvider';
// import { useState } from 'react';
// import NotificationsIcon from '@material-ui/icons/Notifications'
// import VisibilityIcon from '@material-ui/icons/Visibility'
// import { SignalsScoreGear } from 'cf-components/SignalsScoreGear';

// interface AccountListTableProps {
//   handleAlert: (domain: string) => void
// }

// export default function AccountListTable ({ handleAlert }: AccountListTableProps) {
//   // Possibly can make this a column type
//   function DisplayScore ({ row }: { row: Account }) {
//     return <SignalsScoreGear score={row.engagementScore} placement='left' domain={row.domain} />
//   }

//   const fields: Fields<Account> = [
//     { name: 'name', type: 'companyAvatar', label: 'Company Name' },
//     { name: 'accountType', type: 'text', minWidth: 200, emptyState: 'No Account Type' },
//     { name: 'contacts', type: 'text', label: 'Contacts', minWidth: 100, emptyState: '0' },
//     { name: 'opportunityStatus', type: 'opp', label: 'Opportunity Status', minWidth: 200 },
//     { name: 'oppAmountCents', type: 'text', label: 'Opportunity Amount', noDisplay: true },
//     { name: 'ownerUserId', type: 'userAvatar', label: 'Account Owner', minWidth: 300 },
//     { name: 'engagementScore', type: 'custom', component: DisplayScore, label: 'Signals Score', minWidth: 200 }
//   ]

//   const templateColumns = '80px 1fr 1fr 1fr 1fr 1fr 120px 80px'
//   const [selectedAccounts, setSelectedAccounts] = useState([])

//   const actions: Actions<Account> = [
//     { name: 'Go to Account Page', action: (row) => window.open(`/#/accounts/${row.domain}`), icon: VisibilityIcon }
//   ]
//   if (handleAlert) {
//     actions.push({ name: 'Create Alert for Account', action: (row) => handleAlert(row.domain), icon: NotificationsIcon })
//   }

//   return (
//     <div>
//       <TableProvider<Account>
//         ObjectClass={Account}
//         fields={fields}
//         actions={actions}
//         noEmail
//         gridTemplateColumns={templateColumns}
//         initialSearchParams={{
//           filters: [
//             [{ field: 'snoozed_until_timestamp', operator: 'lt', value: new Date().toISOString() }],
//             [{ field: 'snoozed_until_timestamp', operator: 'is', value: null }]
//           ],
//           sortColumn: 'name',
//           pageNumber: 1,
//           sortOrder: 'asc',
//           pageSize: 20
//         }}
//       >
//         <TableList<Account> selected={selectedAccounts} setSelected={setSelectedAccounts} />
//       </TableProvider>
//     </div>
//   )
// }
