import Avatar from '@material-ui/core/Avatar'
import { useDebounce } from 'use-debounce'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete'
import {
  AccountType,
  AccountOwnerField,
  Bot,
  Channel,
  CRMRecordType,
  Durations,
  Email,
  EmployeeRange,
  HighIntentCategory,
  Industry,
  KeywordGroups,
  Locations,
  MeetingType,
  OpportunityStage,
  OpportunityType,
  Persona,
  Play,
  RevenueRange,
  Segment,
  Team,
  Tenant,
  User,
  ContactList,
  ConversionAction,
  Cadence
} from 'classes/classes'
import { defaultSearchParams, LoadAllProps } from 'classes/queryHelpers'
import { Sequence } from 'classes/sequences'
import FilterView from 'classes/filterView'
import Tag from 'classes/tags'
import './custom-styles.css'
import { Icon, LabeledCheckbox, Paper, Popper, Tooltip, Typography } from 'library/materialUI'
import { ChangeEvent, useState, useEffect } from 'react'
import { isEqual } from 'lodash'
import { ActiveSequences } from 'classes/activeSequences'
import Loader from 'library/loading/Loader'
import { getInitials } from './Avatars'
import { useTheme } from '@material-ui/styles'
import { Technologies } from 'classes/technologies'
import { PortalTeam } from 'classes/portalTeams'
import CustomField from 'classes/customFields'
import { CRMField } from 'classes/crmFields'
import { IconType } from 'library/materialUI/Icon'
import { Chip } from '@material-ui/core'

declare global {
  interface Window { autocompleteOpen: boolean }
}

const useStyles = makeStyles(theme => ({
  renderOption: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: 10
  },
  inputRoot: {
    minWidth: 0
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  plusIcon: {
    display: 'flex',
    marginLeft: '15px',
    cursor: 'pointer'
  },
  fabActive: {
    backgroundColor: '#e6efd2',
    color: '#81af20',
    borderRadius: '50%',
    padding: 5
  },
  fab: {
    backgroundColor: '#e5e5e5',
    color: '#868686',
    borderRadius: '50%',
    padding: 5
  }
}))

const validLocation: boolean = (window.location.href.includes('playmaker') || window.location.href.includes('bots') || window.location.href.includes('plays') || window.location.href.includes('chat_availability') || window.location.href.includes('forms') || window.location.href.includes('global_routing'))

export function RenderInput (props: any): JSX.Element {
  const classes = useStyles()
  const object = props.objectClass
  const textFieldProps: Record<string, any> = {}
  const inputProps = props.inputProps || {}
  const singleObjectType = props.objectType?.slice(0, -1)
  const objectType = props.multiple ? props.objectType : singleObjectType
  const selected: boolean = props.multiple ? props.selected.length > 0 : props.selected
  Object.keys(props).forEach(key => {
    if (key !== 'objectClass') {
      textFieldProps[key] = props[key]
    }
  })
  if (!inputProps?.value && props?.value) {
    // There maybe a bug in the Autocomplete component. In some use cases
    // the value is not set in the inputProps on initial load
    // so we see the label instead of the currently selected value. This
    // is a workaround for that issue.
    inputProps.value = props.value
  }

  return (
    <div className={classes.dropDownContainer}>
      <TextField
        {...textFieldProps}
        variant='outlined'
        inputProps={inputProps}
        label={props.label}
        placeholder={props.placeholder}
      />
      {object.create && !(object.edit && selected) && validLocation ? (
        <Tooltip title={`Create new ${singleObjectType}`}>
          <div onClick={() => object.create()} className={classes.plusIcon}>
            <Icon icon='addBox' size='md' />
          </div>
        </Tooltip>
      ) : (
        <>
        </>
      )}
      {object.edit && selected && validLocation ? (
        <Tooltip title={`Edit ${objectType}`}>
          <div
            onClick={() => {
              if (props.multiple) {
                object.create()
              } else {
                object.edit(props.selected)
              }
            }}
            className={classes.plusIcon}
          >
            <Icon icon='edit' specificSize={20} color='#9933FF' />
          </div>
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  )
}

function GetColor (index: number) {
  const theme = useTheme() as { palette: { primary: { main: string, light: string } } }
  const color = index % 2 ? theme?.palette?.primary?.main : theme?.palette?.primary?.light ? theme.palette.primary.light : '#343434'
  return color
}

let bgColorIndex = 0

function AvatarDiv (props: any): JSX.Element {
  const classes = useStyles()
  const option = props.option
  if (option.avatarUrl) {
    return (
      <Avatar
        src={option.avatarUrl}
        className={classes.avatar}
      />
    )
  }
  if (option.objectType === 'user') {
    const fallback = option.name ? option.name.charAt(0) : 'NA'
    const initials = getInitials(option.name, fallback)
    const bgColor = GetColor(bgColorIndex)
    bgColorIndex += 1
    return (
      <Avatar
        style={{ backgroundColor: bgColor }}
        className={classes.avatar}
      >
        {initials}
      </Avatar>
    )
  }
  return <></>
}

export function RenderOption (props: any): JSX.Element {
  const classes = useStyles()
  const option = props.option
  let tooltip = ''
  if (option.disabled) {
    tooltip = option.disabledMessage || ''
  }

  if (props.multiple) {
    return (
      <Tooltip title={tooltip}>
        <div className={classes.renderOption}>
          <Checkbox
            color='primary'
            checked={props.checked}
          />
          <AvatarDiv option={option} />
          {option.name}
          {option.type && (
            <Typography variant='body2' style={{ display: 'flex', marginLeft: 'auto' }}>
              {option.type.charAt(0).toUpperCase() + option.type.slice(1)}
            </Typography>
          )}
        </div>
      </Tooltip>
    )
  }
  return (
    <Tooltip title={tooltip}>
      <div className={classes.renderOption}>
        <AvatarDiv option={option} />
        {option.name}
      </div>
    </Tooltip>
  )
}

const mapping = {
  accountTypes: AccountType,
  accountOwnerFields: AccountOwnerField,
  bots: Bot,
  channels: Channel,
  crmRecordTypes: CRMRecordType,
  durations: Durations,
  emails: Email,
  industries: Industry,
  highIntentCategories: HighIntentCategory,
  keywordGroups: KeywordGroups,
  location: Locations,
  meetingTypes: MeetingType,
  opportunityStages: OpportunityStage,
  opportunityTypes: OpportunityType,
  personas: Persona,
  plays: Play,
  revenueRanges: RevenueRange,
  employeeRanges: EmployeeRange,
  segments: Segment,
  activeSequences: ActiveSequences,
  tags: Tag,
  teams: Team,
  tenants: Tenant,
  users: User,
  contactList: ContactList,
  sequences: Sequence,
  filters: FilterView,
  conversionActions: ConversionAction,
  cadence: Cadence,
  technologies: Technologies,
  portalTeams: PortalTeam,
  customFields: CustomField,
  crmFields: CRMField
}

export type PickerObjectType = keyof typeof mapping

interface ActiveProps {
  icon: IconType
  notActiveMsg: string
}

export interface PickerProps {
  selection: number | string | undefined | null | PartialObject
  setSelection: (value: number | string | PartialObject) => void
  multiple?: false
  objectType: PickerObjectType
  label?: string
  placeholder?: string
  loadAllProps?: LoadAllProps
  errors?: boolean
  onBlur?: (e: any) => void
  returnFull?: boolean
  queryBackend?: boolean
  optionSchema?: (option: PartialObject) => boolean
  sortOptions?: boolean
  activeProps?: ActiveProps // Dependant on objects that have active variables
  ignoreDisabled?: boolean
}

export interface MultiPickerProps {
  selection: number[] | string[] | PartialObject[] | undefined | null
  setSelection: (value: number[] | string[] | PartialObject[]) => void
  multiple: true
  objectType: PickerObjectType
  label?: string
  placeholder?: string
  loadAllProps?: LoadAllProps
  errors?: boolean
  selectAll?: boolean
  onBlur?: (e: any) => void
  queryBackend?: boolean
  returnFull?: boolean
  optionSchema?: (option: PartialObject) => boolean
  max?: number
  sortOptions?: boolean
  activeProps?: ActiveProps // Dependant on objects that have active variables
  ignoreDisabled?: boolean
}

interface SharedPickerProps {
  renderValue: string
  handleInputChange: any
  resetSearch: any
  noOptionsText: string
  items: PartialObject[] | []
}

export interface PartialObject {
  id: any
  name: string
  group?: string
  type?: string
  disabled?: boolean
  active?: boolean
}

export default function Picker (props: PickerProps | MultiPickerProps): JSX.Element {
  const object = mapping[props.objectType]
  const [searching, setSearching] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadAllProps, setLoadAllProps] = useState(props?.loadAllProps)
  const [debouncedProps] = useDebounce(loadAllProps, 1000)
  const { data, isLoading } = object.loadAll(debouncedProps)
  const ids = props?.multiple ? props?.selection : [props?.selection]
  const { data: selectedData, isLoading: selectedIsLoading } = object.loadAll({
    searchParams: {
      ids,
      extraHeaders: loadAllProps?.searchParams?.extraHeaders,
      queryParams: loadAllProps?.searchParams?.queryParams
    }
  })
  const selectedItems = selectedData?.list || []
  const allItems = data?.list || []
  const selectedIDs = selectedItems.map((item: any) => item.id)
  allItems.forEach((item: any) => {
    if (!selectedIDs.includes(item.id)) {
      selectedItems.push(item)
    }
  })
  const items: PartialObject[] | [] = searching && isLoading ? [] : selectedItems
  const search: any = loadAllProps?.searchParams?.search
  useEffect(() => {
    if (searching && !isLoading && !selectedIsLoading) {
      setSearching(false)
    }
    if (firstLoad && !isLoading && !selectedIsLoading) {
      setFirstLoad(false)
    }
  }, [isLoading, selectedIsLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  if ((firstLoad && !searching && (isLoading || selectedIsLoading)) || typeof data === 'undefined') {
    return (
      <Loader type='dropdown' />
    )
  }

  if (props.sortOptions) {
    items.sort((a, b) => a.name.localeCompare(b.name))
    items.sort((a, b) => a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1)
  }

  if (!searching && isLoading) {
    return (<></>)
  }

  const handleInputChange = ({ value, reason }: { event: ChangeEvent<{}>, value: any, reason: AutocompleteInputChangeReason }) => {
    if (reason === 'reset') {
      // no need for a new search on a reset
      return
    }
    const searchParams = loadAllProps && loadAllProps.searchParams ? { ...loadAllProps.searchParams } : { ...defaultSearchParams }
    searchParams.search = value
    const newLoadAllProps = { searchParams }
    // const v2 = newLoadAllProps?.searchParams?.search
    setSearching(true)
    setLoadAllProps({ ...newLoadAllProps })
  }
  const resetSearch = (event: any) => {
    if (search) {
      handleInputChange({ event, value: '', reason: 'input' })
    }
  }
  const noOptionsText = searching && isLoading ? '…searching…' : 'No options'

  if (props.multiple) {
    return (
      <MultiPicker
        props={props}
        shared={{
          renderValue: search,
          noOptionsText: noOptionsText,
          handleInputChange: handleInputChange,
          items: items,
          resetSearch: resetSearch
        }}
      />
    )
  } else {
    return (
      <SinglePicker
        props={props}
        shared={{
          renderValue: search,
          noOptionsText: noOptionsText,
          handleInputChange: handleInputChange,
          items: items,
          resetSearch: resetSearch
        }}
      />
    )
  }
}

function MultiPicker ({ props, shared }: { props: MultiPickerProps, shared: SharedPickerProps }): JSX.Element {
  const classes = useStyles()
  const object = mapping[props.objectType]
  const [checkAll, setCheckAll] = useState(false)
  const selection = props?.selection || []
  let selectAllProps = {}
  let value = null
  const renderOption = (option: any) => (
    <RenderOption
      option={option}
      checked={selection.includes(option.id)}
      multiple
    />)
  const placeholder = selection.length > 0 ? undefined : props?.placeholder

  const defaultValue = shared.items.filter(u => selection.includes(u.id))
  const onChange = (event, obj) => {
    if (props.returnFull) {
      props.setSelection(obj)
      return
    }
    const currentIDs = obj.map((o: any) => o.id)
    if (props.selectAll) {
      const allIDs = shared.items.map((o: any) => o.id)
      if (isEqual(allIDs, currentIDs)) {
        setCheckAll(true)
      } else {
        setCheckAll(false)
      }
    }
    props.setSelection(currentIDs)
    shared.resetSearch(event)
  }

  const checkAllChange = () => {
    setCheckAll(!checkAll)
    if (checkAll) {
      props.setSelection([] as any)
    } else {
      const currentIDs: number[] = shared.items.map((item: any) => item.id)
      props.setSelection(currentIDs as any)
    }
  }

  value = shared.items.filter(u => selection.includes(u.id))

  if (props.selectAll) {
    selectAllProps = {
      PopperComponent: (param: any) => {
        return (
          <Popper {...param}>
            <Paper>
              <div style={{ marginLeft: 10 }}>
                <LabeledCheckbox
                  checked={checkAll}
                  onChange={checkAllChange}
                  label='Select All'
                  preventDefault
                />
              </div>
            </Paper>
            <Paper {...param} />
          </Popper>
        )
      }
    }
  }
  return (
    <div className='autocomplete' style={{ width: '100%' }}>
      <Autocomplete
        defaultValue={defaultValue}
        noOptionsText={shared.noOptionsText}
        options={shared.items}
        fullWidth
        value={value}
        inputValue={props.queryBackend ? (shared.renderValue || '') : undefined}
        multiple
        autoHighlight
        disableCloseOnSelect
        limitTags={2}
        onBlur={(e: any) => {
          return (
            props.onBlur ? props.onBlur(e) : console.log
          )
        }}
        onInputChange={(event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => props.queryBackend ? shared.handleInputChange({ event, value, reason }) : undefined}
        onChange={onChange}
        getOptionDisabled={(option) => {
          if (props.max && props.selection && props.selection.length >= props.max) {
            if (props.selection.includes(option.id)) {
              return false
            }
            return true
          }
          if (props.ignoreDisabled) {
            return false
          }
          return option.disabled
        }}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.group ? option.group : ''}
        renderOption={renderOption}
        renderInput={(params) => <RenderInput {...params} value={shared.renderValue} label={props?.label} placeholder={placeholder} error={props.errors} objectClass={object} multiple objectType={props.objectType} selected={selection} />}
        renderTags={props.activeProps ? (tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            return (
              <Chip
                key={index}
                {...getTagProps({ index })}
                variant='outlined'
                label={option.name}
                icon={
                  (
                    <Tooltip title={option.active ? 'Currently in use' : props.activeProps?.notActiveMsg}>
                      <div
                        className={(option.active ? classes.fabActive : classes.fab)}
                      >
                        <Icon icon={props.activeProps?.icon} size='sm' />
                      </div>
                    </Tooltip>
                  )
                }
              />
            )
          })
        } : undefined}
        size='small'
        onOpen={() => { window.autocompleteOpen = true }}
        onClose={() => { setTimeout(() => { window.autocompleteOpen = false }, 100) }}
        {...selectAllProps}
      />
    </div>
  )
}

function SinglePicker ({ props, shared }: { props: PickerProps, shared: SharedPickerProps }): JSX.Element {
  const classes = useStyles()
  const object = mapping[props.objectType]
  const renderOption = (option: any): JSX.Element => (<RenderOption option={option} />)
  let onChange = (event: any, obj: any): void => {
    props.setSelection(obj ? obj.id : null)
    shared.resetSearch(event)
  }
  let value: any = null
  let defaultValue: any = null

  if (props.returnFull) {
    onChange = (event: any, obj: any): void => {
      props.setSelection(obj)
      shared.resetSearch(event)
    }
  }
  if (props.selection && shared.items) {
    const filtered = shared.items.filter(i => i.id === props.selection)
    if (filtered.length > 0) {
      defaultValue = filtered[0]
    }
    if (!value) {
      value = defaultValue
    }
  }
  const placeholder = props.selection !== null ? undefined : props?.placeholder
  return (
    <div className='autocomplete' style={{ width: '100%' }}>
      <Autocomplete
        defaultValue={defaultValue}
        noOptionsText={shared.noOptionsText}
        options={shared.items}
        fullWidth
        value={value}
        inputValue={props.queryBackend ? (shared.renderValue || '') : undefined}
        autoHighlight
        limitTags={2}
        onBlur={(e: any) => {
          return (
            props.onBlur ? props.onBlur(e) : console.log
          )
        }}
        onInputChange={(event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => props.queryBackend ? shared.handleInputChange({ event, value, reason }) : undefined}
        onChange={onChange}
        getOptionDisabled={(option) => {
          if (props.ignoreDisabled) {
            return false
          }
          return option.disabled
        }}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.group ? option.group : ''}
        renderOption={renderOption}
        renderInput={(params) => <RenderInput {...params} value={value?.name || shared.renderValue} label={props?.label} placeholder={placeholder} error={props.errors} objectClass={object} selected={props.selection} objectType={props.objectType} />}
        renderTags={props.activeProps ? (tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            return (
              <Chip
                key={index}
                {...getTagProps({ index })}
                variant='outlined'
                label={option.name}
                icon={
                  (
                    <Tooltip title={option.active ? 'Currently in use' : props.activeProps?.notActiveMsg}>
                      <div
                        className={(option.active ? classes.fabActive : classes.fab)}
                      >
                        <Icon icon={props.activeProps?.icon} size='sm' />
                      </div>
                    </Tooltip>
                  )
                }
              />
            )
          })
        } : undefined}
        size='small'
        onOpen={() => { window.autocompleteOpen = true }}
        onClose={() => { setTimeout(() => { window.autocompleteOpen = false }, 100) }}
      />
    </div>
  )
}
