import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { getCompanyInfo } from 'api/company_profile'
import { getDistinctValues } from 'api/organizations'
import { SelectNoOutline } from 'cf-components/material-wrappers/AlternateMuiComponents'
import { BasicModal } from 'cf-components/Modal'
import { ScrollBox } from 'cf-components/ScrollBox'
import { ObjectAvatar } from 'cf-components/table/TableAvatars'
import Account, { AccountList, mostRecentFilter } from 'classes/accounts'
import { Icon } from 'library/materialUI'
import PieChart from 'pages/dashboard/PieChart'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import PuffLoader from 'react-spinners/PuffLoader'
import theme from 'theme'
import NoAccounts from '../../../img/no-accounts.png'
import { IcpEditCard, IcpViewCard } from './AccountICPCard'
import { COUNTRIES, employee_counts, icpFiltersToSegments, isAccountInSegment, revenue_values, STATES } from './accountICPHelpers'
import { Section } from './AccountICPSetup'
import { FullScreenPieChart } from './FullScreenPieChart'
import { ICPFilters, RawData, initialICPFilters } from 'classes/icp'
import { ICPContext } from './ICPProvider'
import InformationIcon from 'library/InformationIcon'

const tableColumns = '2fr 1fr 1fr 1fr 1fr 0.25fr'
const infoIconMsg = 'Below is the last 500 accounts to be seen on your site broken out into ICP and Excluded (non-ICP) accounts'

const useStyles = makeStyles(theme => ({
  loadMoreDiv: {
    marginTop: 10,
    marginBottom: 10
  },
  loadMoreButton: {
    fontSize: '1em',
    padding: '18px 8px'
  },
  pageRow: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr',
    columnGap: '30px',
    '&:not(:last-child)': {
      marginBottom: 12
    }
  },
  card: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    padding: '13px 15px',
    height: '100%',
    maxHeight: 760,
    boxSizing: 'border-box',
    overflowY: 'hidden'
  },
  loadingCtn: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 24
  },
  tableHeaders: {
    display: 'grid',
    gridTemplateColumns: tableColumns,
    alignItems: 'center',
    padding: '6px 0',
    fontSize: '.8em',
    paddingRight: 8,
    marginBottom: 4
  },
  table: {
    height: 'calc(100% - 21px)',
    display: 'grid',
    gridTemplateRows: 'auto 1fr'
  },
  tableTitle: {
    fontSize: 14,
    color: 'black',
    fontWeight: 600
  },
  rowsCtn: {
    backgroundColor: '#F8F8F8'
  },
  rowsAlt: {
    backgroundColor: theme.palette.primary.contrastText
  },
  scrollBoxCtn: {
    height: '100%',
    maxHeight: 585
  },
  row: {
    display: 'grid',
    gridTemplateColumns: tableColumns,
    fontSize: '.8em',
    marginRight: 8,
    alignItems: 'center',
    padding: '6px 0',
    '&:not(:first-child)': {
      borderTop: '1px solid #E6D7FA'
    }
  },
  invalidRowField: {
    color: '#FF0000'
  },
  numMatches: {
    borderRadius: 4,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '0px 2px',
    width: 'fit-content',
    marginLeft: 10,
    height: 18
  },
  iconColor: {
    color: theme.palette.primary.main
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  }
}))

interface MatchingAccounts {
  id: Account['id']
  reasons?: string[]
}

export default function AccountICPHub ({ skipCRM }: { skipCRM: boolean }): JSX.Element {
  const { activeStep, localICP, icpFilters } = useContext(ICPContext)
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [outPageSize, setOutPageSize] = useState(10)
  const [inPageSize, setInPageSize] = useState(10)
  const [variableSaveFunc, setVariableSaveFunc] = useState(() => (arg: any) => { return arg })
  const [funcVars, setFuncVars] = useState({})
  const [matchingAccounts, setMatchingAccounts] = useState<{ in: MatchingAccounts[]; out: MatchingAccounts[]; }>({ in: [], out: [] })
  const { data: accounts, isLoading: isAccountsLoading } = Account.loadAll({ searchParams: mostRecentFilter })
  const { data: companyInfo } = useQuery<Record<string, any>>('company_profile', () => getCompanyInfo().then(res => res.data), { staleTime: 120000 })
  const { data: distinctIndustries } = useQuery(
    ['organizations', 'distinct', 'industries'],
    () => getDistinctValues('industry').then(res => res.ok ? res.data : []),
    { staleTime: 120000 }
  )
  const currentIcpFieldOptions = useMemo<ICPFilters<'formatted'>>(() => ({
    state: STATES,
    country: COUNTRIES,
    industry: distinctIndustries
  }), [distinctIndustries])

  useEffect(() => {
    if (accounts.list.length) {
      const segments = icpFiltersToSegments(icpFilters)
      const nextMatchingAccounts: {
        in: MatchingAccounts[]
        out: MatchingAccounts[]
      } = { in: [], out: [] }
      accounts.list.forEach(account => {
        const reasons = isAccountInSegment(segments, account, localICP)
        if (reasons === true) {
          nextMatchingAccounts.in.push({ id: account.id })
        } else {
          nextMatchingAccounts.out.push({ reasons, id: account.id })
        }
      })
      setMatchingAccounts(nextMatchingAccounts)
    }
  }, [accounts.list.length, icpFilters, localICP]) // eslint-disable-line react-hooks/exhaustive-deps

  const messageText = <Typography>{activeStep === 2 ? "If you edit the ICP, the accounts in the ICP segment and any campaigns they're enrolled in will be affected." : 'All accounts that match your new criteria will be added to the updated ICP, not just the selected category or account.'}<br /><br />Would you like to continue?</Typography>
  return (
    <div>
      {activeStep < 2 &&
        <div className={classes.pageRow}>
          <Section>
            {skipCRM ? (
              <Typography>
                Set criteria for your ICP here. We recommend finding commonalities across your closed-won opportunities.
              </Typography>
            ) : (
              <Typography>
                Based on the analysis, this is your ideal customer profile (ICP). You can edit your ICP if you would like to change or remove any fields.
              </Typography>
            )}
          </Section>
          <Section>
            {skipCRM ? (
              <Typography>
                All future accounts matching the ICP will also be added to that segment. You can edit the segment or export the list of accounts from within the segment.
              </Typography>
            ) : (
              <Typography>
                We have created an "ICP Accounts" segment for your company with accounts matching the criteria. All future accounts matching the ICP will also be added to that segment. You can edit the segment or export the list of accounts from within the segment.
              </Typography>
            )}
          </Section>
        </div>}
      <div className={classes.pageRow}>
        <div className={classes.card}>
          {editMode ? (
            <IcpEditCard
              companyName={companyInfo?.attributes?.company_name || 'Your Company'}
              icpFieldOptions={currentIcpFieldOptions}
            />
          ) : (
            <IcpViewCard
              companyName={companyInfo?.attributes?.company_name || 'Your Company'}
              editICP={() => setEditMode(true)}
            />
          )}
        </div>
        <div className={classes.card}>
          {!isAccountsLoading && activeStep >= 1 && accounts.list.length === 0 ? (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <img
                src={NoAccounts}
                alt='No Accounts'
              />
              <div style={{ margin: 10 }}>
                <Typography variant='h1'>No accounts</Typography>
              </div>
              <div style={{ margin: 10 }}>
                <Typography variant='body2'>
                  You haven't added any accounts yet. Add accounts to begin setting up your ICP.
                </Typography>
              </div>
              <div style={{ margin: 20 }}>
                <Link
                  underline='none'
                  target='_blank'
                  href='#/account_management/target_accounts'
                  rel='noreferrer'
                >
                  <Button color='primary' variant='contained'>Add Accounts</Button>
                </Link>
              </div>
            </div>
          ) : (
            <AccountSmallTable
              pageSize={inPageSize}
              setPageSize={setInPageSize}
              isLoading={isAccountsLoading}
              accountDict={accounts.dict}
              matches={matchingAccounts.in}
              tableTitle='ICP'
            />
          )}
        </div>
      </div>
      <div className={classes.pageRow}>
        {skipCRM || Object.keys(icpFilters?.rawData?.matchedFields || {}).length === 0 ? (<div />) : (
          <div className={classes.card} style={{ maxHeight: '580px' }}>
            <AccountPieChart
              isLoading={isAccountsLoading}
              setFuncVars={setFuncVars}
              setOpenModal={setOpenModal}
              setVariableSaveFunc={setVariableSaveFunc}
            />
          </div>)}
        <div className={classes.card} style={{ maxHeight: '580px' }}>
          <AccountSmallTable
            pageSize={outPageSize}
            setPageSize={setOutPageSize}
            isLoading={isAccountsLoading}
            accountDict={accounts.dict}
            matches={matchingAccounts.out}
            tableTitle='Excluded'
            setFuncVars={setFuncVars}
            setOpenModal={setOpenModal}
            setVariableSaveFunc={setVariableSaveFunc}
          />
        </div>
      </div>
      {openModal &&
        <BasicModal
          title='Update ICP'
          confirmButtonText='UPDATE ICP'
          open={openModal}
          onHide={() => setOpenModal(false)}
          confirmAction={() => {
            variableSaveFunc(funcVars)
            setOpenModal(false)
          }}
          cancelButtonText='CANCEL'
          message={messageText}
          confirmVariant='contained'
        />}
    </div>
  )
}

function AccountSmallTable (props: {
  accountDict: AccountList['dict']
  matches: MatchingAccounts[]
  isLoading: boolean
  tableTitle: String
  setFuncVars?: any
  setOpenModal?: any
  pageSize: number
  setPageSize: any
  setVariableSaveFunc?: any
}): JSX.Element {
  const { activeStep, icp, icpFilters, icpFiltersDispatch } = useContext(ICPContext)
  const classes = useStyles()
  const activeColor = activeStep < 2 ? '#F8F8F8' : '#FFFFFF'
  const elementHeight = props.tableTitle === 'Excluded' ? 490 : '100%'

  const updateICP = (params: any): void => {
    const { reasons, account } = params
    reasons.forEach((reason: string) => {
      const accountKey: string = reason === 'state' ? 'addressState' : reason === 'annualRevenue' ? 'annualRevenueRange' : reason === 'employeeCount' ? 'employeeCountRange' : reason

      if (reason === 'industry' || reason === 'country') {
        const currentData = icpFilters[reason] || []
        currentData.push(account[accountKey])

        icpFiltersDispatch({ [reason]: [...currentData] })
      } else if (reason === 'annualRevenue' || reason === 'employeeCount') {
        const highVal = reason === 'annualRevenue' ? 12 : 10
        let low = 0
        let high: number = highVal

        if (icpFilters) {
          const keys: string[] = reason === 'annualRevenue' ? revenue_values : employee_counts
          const keyIndex: number = keys.indexOf(account[accountKey])

          low = (icpFilters[reason] || {}).gt || low
          high = (icpFilters[reason] || {}).lt || high

          low = (keyIndex > -1 && keyIndex < low) ? keyIndex : low
          high = keyIndex >= high ? keyIndex + 1 : high
        }
        icpFiltersDispatch({ [reason]: { gt: low, lt: high } })
      }
    })
  }

  const displayedMatches = props.isLoading ? [] : props.matches.slice(0, props.pageSize)
  return (
    <>
      <div style={{ display: 'flex', marginBottom: 5, alignItems: 'center' }}>
        <span className={classes.tableTitle}>{props.tableTitle} Accounts</span>
        <span style={{ marginLeft: 5 }}> <InformationIcon color='grey' message={infoIconMsg} /></span>
        <span className={classes.numMatches}>{props.matches?.length || 0} accounts</span>
        {props.tableTitle === 'ICP' && icp?.id && (
          <span style={{ marginLeft: 'auto' }}>
            <Link
              underline='none'
              target='_self'
              href={`#/account_segments/${icp.segmentID}`}
            >
              <Typography variant='subtitle1' color='primary'>Go To Segment {'>'}</Typography>
            </Link>
          </span>)}

      </div>
      <div className={classes.table}>
        <div className={classes.tableHeaders}>
          <div>Company</div>
          <div>Industry</div>
          <div>Location</div>
          <div>Ann. Revenue</div>
          <div>Employee Count</div>
        </div>
        <div className={classes.scrollBoxCtn}>
          <ScrollBox className={activeStep < 2 ? classes.rowsCtn : classes.rowsAlt} noScrollBar backgroundColor={activeColor} style={{ height: elementHeight }}>
            {props.isLoading ? (
              <div className={classes.loadingCtn}>
                <PuffLoader
                  color={theme.palette.primary.main}
                />
              </div>
            ) : displayedMatches.map(({ id, reasons = [] }) => {
              const account = {
                ...props.accountDict[id],
                avatarUrl: `/api/logo-service/logo/${props.accountDict[id].domain}.png`,
                subtitle: props.accountDict[id].domain,
                link: `/accounts/${props.accountDict[id].domain}`
              }
              return (
                <div className={classes.row} key={id}>
                  <ObjectAvatar row={account} hasLink />
                  <div className={reasons.includes('industry') ? classes.invalidRowField : undefined}>
                    {account.industry}
                  </div>
                  <div className={reasons.includes('country') ? classes.invalidRowField : undefined}>
                    {account.country}
                  </div>
                  <div className={reasons.includes('annualRevenue') ? classes.invalidRowField : undefined}>
                    {account.annualRevenueRange}
                  </div>
                  <div className={reasons.includes('employeeCount') ? classes.invalidRowField : undefined}>
                    {account.employeeCountRange}
                  </div>
                  {props.tableTitle === 'Excluded' &&
                    <Tooltip title='Add excluded criteria to ICP' placement='left'>
                      <AddCircleIcon
                        className={classes.iconColor}
                        onClick={() => {
                          // updateICP(reasons, account)
                          props.setFuncVars({ reasons, account })
                          props.setOpenModal(true)
                          props.setVariableSaveFunc(() => updateICP)
                        }}
                      />
                    </Tooltip>}
                </div>
              )
            })}
            {displayedMatches.length > 0 && displayedMatches.length < props.matches.length && (
              <div className={classes.loadMoreDiv}>
                <Button
                  fullWidth
                  color='primary'
                  className={classes.loadMoreButton}
                  onClick={() => { props.setPageSize(props.pageSize + 40) }}
                >
                  Load More
                </Button>
              </div>
            )}
          </ScrollBox>
        </div>
      </div>
    </>
  )
}

const addToICP = (params: any): void => {
  /** params passed into function:
   *    changed: the slice of the pie chart that has been clicked on,
   *    icpFiltersRef: ICPFilters<'formatted'>,
   *    graphedAttribute: string; the type of graph that is currently being viewed,
   *    icpFiltersDispatch: dispatch to change the ICP filters,
   *    keys: string[]; keys for the revenue and employee bins used in the ICP,
   *    matchData: the field that currently match the calculated ICP */
  const { changed, icpFiltersRef, graphedAttribute, icpFiltersDispatch, keys } = params

  const sliceName = [changed.target._dataItem.properties.category]

  if (graphedAttribute.current === 'annual_revenue' || graphedAttribute.current === 'number_of_employees') {
    const clickedSlice = sliceName[0]

    const updatedKey = graphedAttribute.current === 'annual_revenue' ? 'annualRevenue' : 'employeeCount'
    const keyData = icpFiltersRef.current[updatedKey]

    const highVal = graphedAttribute.current === 'annual_revenue' ? 12 : 10
    let low = (keyData || {}).gt || 0
    let high = (keyData || {}).lt || highVal

    let newIndex: number | null = keys?.current?.indexOf(clickedSlice)
    newIndex = (newIndex != null && newIndex > -1) ? newIndex : null
    const updateIndex = (newIndex != null && newIndex < low) ? 'low' : (newIndex && newIndex >= high) ? 'high' : 'none'

    low = (updateIndex === 'low' && newIndex != null) ? newIndex : low
    high = (updateIndex === 'high' && newIndex != null) ? newIndex + 1 : high

    icpFiltersDispatch({ [updatedKey]: { gt: low, lt: high } })
  } else {
    const icpAttributeData = [...icpFiltersRef.current[graphedAttribute.current]]

    icpFiltersDispatch({ [graphedAttribute.current]: [...icpAttributeData, ...sliceName] })
  }
}

function AccountPieChart (props: {
  isLoading: boolean
  setFuncVars: any
  setOpenModal: any
  setVariableSaveFunc: any
}): JSX.Element {
  const { icpFilters, icpFiltersDispatch } = useContext(ICPContext)
  const classes = useStyles()
  const [graphedAttribute, setGraphedAttribute] = useState('annual_revenue')
  const [modalOpen, setModalOpen] = useState(false)
  const data: Partial<RawData['counts']> | undefined = icpFilters?.rawData?.counts[graphedAttribute as keyof RawData['counts']]
  let matchedData: any = icpFilters?.rawData?.matchedFields[graphedAttribute as keyof RawData['matchedFields']]
  const matchedDataRef = useRef(matchedData)
  const icpFiltersRef = useRef(icpFilters)
  const keys: string[] = useMemo(() => Object.keys(data || []), [data])
  const keysRef = useRef(keys)
  const graphedAttributeRef = useRef(graphedAttribute)

  useEffect(() => {
    if (icpFilters) {
      icpFiltersRef.current = icpFilters
    }
    if (keys) {
      keysRef.current = keys
    }
    if (graphedAttribute) {
      graphedAttributeRef.current = graphedAttribute
    }
    if (matchedData) {
      matchedDataRef.current = matchedData
    }
  }, [icpFilters, keys, graphedAttribute, matchedData])

  const graphOptions = [
    { value: 'industry', label: 'Industry' },
    { value: 'country', label: 'Location' },
    { value: 'annual_revenue', label: 'Annual Revenue' },
    { value: 'number_of_employees', label: 'Employee Count' }
  ]

  const transformedData: any[] = []
  let orderedData: {} = {}

  if (data && matchedData) {
    let counts: any[] = Object.values(data)

    if (graphedAttribute === 'annual_revenue' || graphedAttribute === 'number_of_employees') {
      const updatedAttribute = graphedAttribute === 'annual_revenue' ? 'annualRevenue' : 'employeeCount'
      const highVal = initialICPFilters[updatedAttribute].lt
      const low = icpFilters[updatedAttribute]?.gt || 0
      const high = icpFilters[updatedAttribute]?.lt || highVal

      let orderedKeys: string[] = []
      if (graphedAttribute === 'annual_revenue') {
        revenue_values.forEach(value => {
          orderedKeys.push(value.replaceAll(' ', ''))
        })
      } else {
        orderedKeys = employee_counts
      }

      keys.sort((a, b) => orderedKeys.indexOf(a) - orderedKeys.indexOf(b))
      orderedKeys = []
      orderedData = keys.reduce(
        (obj: any, key: any) => {
          obj[key] = data[key as keyof RawData['counts']]
          return obj
        },
        {}
      )
      counts = Object.values(orderedData)

      for (let i = low; i < high; i++) {
        if (matchedData.indexOf(keys[i]) === -1) {
          matchedData.push(keys[i])
        }
      }
      for (let i = 0; i < low; i++) {
        if (matchedData.indexOf(keys[i]) !== -1) {
          const matchIdxLow = matchedData.indexOf(keys[i])
          matchedData.splice(matchIdxLow, 1)
        }
      }
      for (let i = keys.length - 1; i >= high; i--) {
        if (matchedData.indexOf(keys[i]) !== -1) {
          const matchIdxHigh = matchedData.indexOf(keys[i])
          matchedData.splice(matchIdxHigh, 1)
        }
      }
    } else {
      matchedData = icpFiltersRef.current[graphedAttribute as keyof ICPFilters]
    }
    for (let i = 0; i < keys.length; i++) {
      const matchedIndex = matchedData.indexOf(keys[i])
      const matchedItem = matchedIndex > -1 ? '' : 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S 17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z'
      if (counts[i] > 0) {
        transformedData.push({ category: keys[i], count: counts[i], dataItem: matchedItem })
      }
    }
  }

  const dataOptions = {
    graphedAttribute,
    graphOptions,
    setGraphedAttribute,
    transformedData,
    matchedDataRef,
    icpFiltersRef,
    icpFiltersDispatch,
    keysRef,
    graphedAttributeRef,
    addToICP,
    setFuncVars: props.setFuncVars,
    setOpenModal: props.setOpenModal,
    setVariableSaveFunc: props.setVariableSaveFunc
  }

  return (
    <>
      <div className={`${classes.tableTitle} ${classes.flex}`} style={{ justifyContent: 'space-between' }}>
        <div className={classes.flex} style={{ fontSize: 16 }}>
          Account Makeup By
          <SelectNoOutline
            value={graphedAttribute}
            options={graphOptions}
            onChange={(e: string) => setGraphedAttribute(e)}
          />
        </div>
        <Icon
          icon='fullScreen'
          cursor='pointer'
          color='primary'
          onClick={() => setModalOpen(true)}
        />
      </div>
      <PieChart
        id={`${graphedAttribute}_pie_chart`}
        data={transformedData}
        size={500}
        legendHeight={200}
        orderVertically
        eventFunc={(changed: any) => {
          if (!matchedDataRef.current.includes(changed.target._dataItem.properties.category)) {
            props.setFuncVars({ changed, icpFiltersRef, graphedAttribute: graphedAttributeRef, icpFiltersDispatch, keys: keysRef })
            props.setOpenModal(true)
            props.setVariableSaveFunc(() => addToICP)
          }
        }}
      />
      <FullScreenPieChart
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        dataOptions={dataOptions}
      />
    </>
  )
}
