import { useState, useContext, useRef, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SessionContext, components } from 'session-context'
import AppPage from 'cf-components/AppPage'
import AccountListTable from './AccountListTable'
import AccountModal from './AccountModal'
import { updateAccount, syncIntegrationAccounts, importFromCSV } from 'api/account_management'
import { useHistory } from 'react-router-dom'
import AccountIntegrationSettings from './AccountIntegrationSettings'
import FailedToSyncModal from './FailedToSyncModal'
import SegmentListPage from 'pages/segments/SegmentListPage'
import { useQueryClient } from 'react-query'
import AdminAlertManagement from './AdminAlertManagement'
import AccountICPPage from '../AccountICP/AccountICPPage'
import { EVENT_TYPE, Emitter } from 'emitter'
import { ICPProvider } from '../AccountICP/ICPProvider'
import { Button } from 'library/materialUI'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import SyncIcon from '@material-ui/icons/Sync'
import { isEmpty } from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getIntegrationFeature } from 'api/integrations'
import CustomFieldsPage from 'components/CustomFieldsPage'
import CustomFieldsModal from 'components/CustomFieldsModal'
import { createCustomField } from 'api/custom_fields'

const useStyles = makeStyles(theme => ({
  syncDisplay: {
    display: 'flex',
    color: '#e4850f',
    alignItems: 'center'
  },
  greenText: {
    color: '#64ba6b'
  },
  syncIcon: {
    marginRight: 10
  }
}))

const SyncedAccountsButton = (props: any) => {
  const classes = useStyles()
  const loading = props.loading
  const nonSyncedAccountsMeta = props.nonSyncedAccountsMeta

  if (loading) {
    return (
      <div className={classes.syncDisplay + ' ' + classes.greenText}>
        <CircularProgress size={20} className={classes.greenText + ' ' + classes.syncIcon} />
        <div>
          Syncing Your Accounts...
        </div>
      </div>
    )
  }

  if (!nonSyncedAccountsMeta || isEmpty(nonSyncedAccountsMeta)) {
    return <></>
  }

  if (!nonSyncedAccountsMeta.total_accounts) {
    return (
      <div className={classes.syncDisplay + ' ' + classes.greenText}>
        <SyncIcon className={classes.syncIcon} />
        <div>
          All Accounts Up To Date.
        </div>
      </div>
    )
  }

  if (!nonSyncedAccountsMeta?.accounts_without_website?.length) {
    return (
      <div className={classes.syncDisplay + ' ' + classes.greenText}>
        <SyncIcon className={classes.syncIcon} />
        <div>
          {nonSyncedAccountsMeta?.total_accounts} / {nonSyncedAccountsMeta?.total_accounts} Accounts Synced.
        </div>
      </div>
    )
  }

  const failedToSyncNumber = nonSyncedAccountsMeta?.accounts_without_website?.length || 0
  const totalAccountNumber = nonSyncedAccountsMeta?.total_accounts || 0
  const totalSynced = totalAccountNumber - failedToSyncNumber

  return (
    <div className={classes.syncDisplay} onClick={props.handleOpenModal} style={{ cursor: 'pointer' }}>
      <SyncProblemIcon className={classes.syncIcon} />
      <div>
        {totalSynced} / {nonSyncedAccountsMeta?.total_accounts} Accounts Synced.
      </div>
    </div>

  )
}

function AccountManagementPage () {
  const { user, isOn, canAdd } = useContext(SessionContext)
  const chatServiceUrl = user.links.chat_service || 'localhost:8002/api/chat-service/a'
  const queryClient = useQueryClient()
  const [modalOpen, setModalOpen] = useState(false)
  const [crmModalOpen, setCRMModalOpen] = useState(false)
  const [fieldModalOpen, setFieldModalOpen] = useState(false)
  const [CSVImportErrors, setCSVImportErrors] = useState<any>([])
  const [saveICPDisabled, setSaveICPDisabled] = useState(true)
  const history = useHistory()
  const saveICPRef = useRef<Function | null>(null)
  const resetICPRef = useRef<Function | null>(null)

  // Sync account status
  const [nonSyncedAccountsMeta, setNonSyncedAccountsMeta] = useState({})
  const [failedToSyncModalOpen, setFailedToSyncModalOpen] = useState(false)
  const [syncLoading, setSyncLoading] = useState(false)
  const [accountSyncButton, setAccounSyncButton] = useState(<></>)

  // Account Segments
  const [segmentModalOpen, setSegmentModalOpen] = useState(false)
  const [refreshSegmentPage, setRefreshSegmentPage] = useState(0)
  const segmentsOn = isOn(components.SEGMENTS)
  const canAddSegments = canAdd(components.SEGMENTS)

  // Account Alerts
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [alertDomain, setAlertDomain] = useState<string>('')

  function handleAddNewSegment () {
    setSegmentModalOpen(true)
    setRefreshSegmentPage(refreshSegmentPage + 1)
    queryClient.invalidateQueries(['segments', 'list'])
  }

  const handleSubmit = (accountState: any) => {
    // Call function to post to our API
    return updateAccount({ account: accountState.attributes })
      .then(response => {
        setModalOpen(false)
        history.push(`/accounts/${response.data.attributes.domain}`)
      })
  }

  const syncAccounts = () => {
    setSyncLoading(true)
    syncIntegrationAccounts()
      .then(response => {
        if (response.data?.attributes?.accounts_without_website) {
          const accountsWithoutWebsiteMeta = response.data?.attributes || {}
          setNonSyncedAccountsMeta(accountsWithoutWebsiteMeta)
        }
        setSyncLoading(false)
      })
  }

  const handleAlert = (domain: string) => {
    setAlertDomain(domain)
    setAlertModalOpen(true)
  }

  const handleCSVImport = (file: any, csvFallbackUserID: number) => {
    importFromCSV({ csvFile: file, fallbackUserID: csvFallbackUserID })
      .then(response => {
        if (response.ok) {
          setModalOpen(false)
          setCSVImportErrors([])
          Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false, type: 'accounts' })
        } else if (response.errors) {
          setCSVImportErrors(response.errors)
          Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false, type: 'error' })
        }
      }).catch(e => {
        if (e.message.indexOf(':|:') > -1) {
          const msg = e.message.split(':|:')[1]
          setCSVImportErrors([{ detail: 'There was an error while importing your CSV: ' + msg }])
        } else {
          setCSVImportErrors([{ detail: 'There was an unexpected error while importing your CSV: ' + e.message }])
        }
        Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false, type: 'error' })
      })
  }

  const tabs = [
    { name: 'Accounts', url: '/account_management/target_accounts', bgColor: 'white' }
  ]
  const perms = user?.attributes?.perms || {}

  if (segmentsOn) {
    tabs.push(
      {
        name: 'Company Segments',
        url: '/account_management/segments',
        action: handleAddNewSegment,
        actionText: 'Add Segment',
        disabled: !user.attributes.perms?.accounts || !canAddSegments || !segmentsOn,
        tooltip: !canAddSegments ? (
          'You\'re at the maximum segments for your plan. Upgrade your plan or delete a segment to create more.'
        ) : (
          !segmentsOn ? (
            'Segments are not enabled for your account. Please contact support to enable them.'
          ) : ''
        )
      }
    )
  }

  const adminAlerts = perms?.tenant_admin_alerts && perms?.manager

  if (adminAlerts) {
    tabs.push({ name: 'Alerts', url: '/account_management/alerts', action: () => setAlertModalOpen(true), actionText: 'Add Alert' })
  }

  if (perms?.semi_admin && saveICPRef && resetICPRef) {
    const icpProps = {
      disabled: saveICPDisabled,
      save: () => saveICPRef.current(),
      saveText: 'Save Changes',
      reset: () => resetICPRef.current()
    }
    tabs.push(
      { name: 'ICP', url: '/account_management/icp', saveProps: icpProps }
    )
    if (isOn(components.PLAYMAKER)) {
      tabs.push({ name: 'Account Custom Fields', url: '/account_management/custom_fields', action: () => setFieldModalOpen(true), actionText: 'Add Custom Field' })
    }
  }

  function createNewField (values: any) {
    const forObject = 'accounts'
    createCustomField({ values, forObject })
      .then(_ => {
        queryClient.invalidateQueries('custom_fields')
      })
  }

  document.title = 'Account-Based Engagement | Signals'

  const canSync = useCallback(() => {
    getIntegrationFeature('crm.abe').then(response => {
      if (response.data.attributes.integration) {
        setAccounSyncButton(
          <div style={{ display: 'flex' }}>
            <SyncedAccountsButton
              handleOpenModal={setFailedToSyncModalOpen}
              nonSyncedAccountsMeta={nonSyncedAccountsMeta}
              loading={syncLoading}
            />
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setCRMModalOpen(true)}
            >
              Configure Account Sync
            </Button>
          </div>
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    canSync()
  }, [canSync])

  return (
    <>
      <AppPage
        title='Account-Based Engagement'
        tabs={tabs}
        extraButtons={accountSyncButton}
      >
        <AccountListTable handleAlert={handleAlert} />
        {segmentsOn && (
          <SegmentListPage
            permission
            segmentModalOpen={segmentModalOpen}
            setSegmentModalOpen={setSegmentModalOpen}
            refreshSegmentPage={refreshSegmentPage}
            objectType='domains'
          />
        )}
        {adminAlerts && (
          <AdminAlertManagement
            alertModalOpen={alertModalOpen}
            domain={alertDomain}
            onHide={() => { setAlertDomain(''); setAlertModalOpen(false) }}
          />
        )}
        <ICPProvider
          setDisabled={setSaveICPDisabled}
          saveICPRef={saveICPRef}
          resetICPRef={resetICPRef}
        >
          <AccountICPPage />
        </ICPProvider>
        {isOn(components.PLAYMAKER) && (
          <CustomFieldsPage forObject='accounts' />
        )}
      </AppPage>
      <AccountIntegrationSettings
        syncAccounts={syncAccounts}
        open={crmModalOpen} onHide={() => setCRMModalOpen(false)}
        chatServiceUrl={chatServiceUrl}
      />
      <AccountModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        handleSubmit={handleSubmit}
        handleCSVImport={handleCSVImport}
        CSVImportErrors={CSVImportErrors}
      />
      <FailedToSyncModal
        nonSyncedAccountsMeta={nonSyncedAccountsMeta}
        open={Boolean(failedToSyncModalOpen)}
        onHide={() => setFailedToSyncModalOpen(false)}
      />
      <CustomFieldsModal
        open={fieldModalOpen}
        onHide={() => setFieldModalOpen(false)}
        save={createNewField}
      />
    </>
  )
}

export default AccountManagementPage
