import { useDoQuery } from './useDoQuery'
import { doPost, doPatch, doDelete } from 'api/api'
import { pick } from 'lodash'
import { GenericObject } from './genericObject'

interface ChannelList {
  list: Channel[]
  dict: {[id: Channel['id']]: Channel}
}

const dbProps = ['description', 'name', 'botIDs', 'teamIDs', 'userIDs']

export class Channel extends GenericObject {
  description: string
  userIDs: number[]
  botIDs: number[]
  teamIDs: number[]

  get link (): string { return `/channels/${this.id}` }
  get subtitle (): string { return this.createdDate }
  get searchField (): string { return this.name + this.description }

  static loadAll (): { data: ChannelList, isLoading: boolean } {
    return useDoQuery({
      path: '/channels',
      objectClass: Channel,
      useChatURL: true
    })
  }

  static loadOne (ID: Channel['id']): {data: Channel} {
    return useDoQuery({ useChatURL: true, path: `/channels/${ID}`, objectClass: Channel })
  }

  static save (channel: Channel): any {
    const attributes = pick(channel, dbProps)
    const data = {
      type: 'channels',
      id: channel.id,
      attributes: attributes
    }
    if (channel.id) {
      const path = `/channels/${channel.id}`
      return doPatch({ path, data, useChatURL: true })
    } else {
      const path = '/channels'
      return doPost({ path, data, useChatURL: true })
    }
  }

  delete (): Promise<any> {
    const path = `/channels/${this.id}`
    return doDelete({ path: path })
  }

  constructor ({ row }: { row?: Record<string, any> } = {}) {
    super({ row })
    this.description = row?.attributes.description || ''
    this.botIDs = row?.attributes.bot_ids || []
    this.teamIDs = row?.attributes.team_ids || []
    this.userIDs = row?.attributes.user_ids || []
  }
}

export default Channel
