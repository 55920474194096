import { doPatch, doPost, doGet, doDelete, doStreamFetch } from './api'

function contactSearch ({ contactIDs }) {
  const params = new URLSearchParams()
  const path = '/contacts:search'

  const data = {
    type: 'contact_search',
    attributes: {}
  }

  if (contactIDs) {
    data.attributes = {
      contact_ids: contactIDs
    }
  }

  return doPost({
    useChatURL: true,
    path: path + '?' + params.toString(),
    data
  })
}

function deleteContacts ({ contactIDs }) {
  return doDelete({
    useChatURL: true,
    path: '/contacts',
    data: {
      attributes: {
        contact_ids: contactIDs
      }
    }
  })
}

function exportContacts ({ segmentId }) {
  let path = '/contacts:export'
  if (segmentId) {
    path += '?filter[segment_id]=' + segmentId
  }

  return doPost({
    useChatURL: true,
    path,
    data: {}
  })
}

function getContacts ({ search, accountID, segmentId, pageSize, pageNumber, sortby, ascending }) {
  const params = new URLSearchParams()
  const path = '/contacts'

  if (accountID) {
    params.set('filter[account_id]', accountID)
  }

  if (sortby) {
    if (!ascending) {
      params.set('sort', '-' + sortby)
    } else {
      params.set('sort', sortby)
    }
  }

  if (segmentId) {
    params.set('filter[segment_id]', segmentId)
  }

  if (pageSize) {
    params.set('page[size]', pageSize)
  }

  if (pageNumber) {
    params.set('page[number]', pageNumber)
  }

  if (search) {
    params.set('filter[search]', search)
  }

  return doGet({
    useChatURL: true,
    path: path + '?' + params.toString()
  })
}

/**
 * @param {import('react-query').QueryFunctionContext<['contacts', 'count', {
 * filter?: {
 *  search?: string
 *  email?: string
 *  accountID?: string | number
 *  segmentID?: string | number
 * }
 * }] as import('use-cf-query').PaginateQueryKey>} arg2
 * @returns {Promise<number>}
 */
function countContacts ({ queryKey }) {
  const { filter: { search, email, accountID, segmentId } = {} } = queryKey[2]
  const path = '/contacts:count'

  const params = new URLSearchParams()
  if (accountID) {
    params.set('filter[account_id]', accountID)
  }
  if (segmentId) {
    params.set('filter[segment_id]', segmentId)
  }
  if (search) {
    params.set('filter[search]', search)
  }
  if (email) {
    params.set('filter[email]', email)
  }

  return doGet({
    path: path + '?' + params.toString(),
    useChatURL: true
  }).then(res => {
    if (res.ok) {
      return res.data.count
    }
    return 0
  }, () => 0)
}

function getContact ({ personID, includes, contactID, authHeader }) {
  let includeString = ''
  if (includes) {
    includeString = '?include=' + includes.join(',')
  }

  const id = contactID || personID
  const path = `/contacts/${id}${includeString}`

  return doGet({
    path,
    useChatURL: true,
    extraHeaders: authHeader
  })
    .then(personResponse => {
      let attr = personResponse.data.attributes
      const included = personResponse.included || []
      for (const inc of included) {
        if (inc.type === 'accounts') {
          attr = { ...attr, account_id: inc.id }
        }
        if (inc.type === 'users') {
          attr = {
            ...attr,
            created_by_user: {
              id: inc.id,
              name: inc.attributes.name
            }
          }
        }
        if (inc.type === 'meetings') {
          attr = { ...attr, meetings: inc.data }
        }
      }
      const person = personResponse.data
      person.attributes = attr
      return person
    })
}

function saveContact ({ personAttributes, personID, account_id }) {
  const data = {
    type: 'people',
    attributes: personAttributes,
    relationships: {}
  }

  if (personID) {
    data.id = parseInt(personID)
  }

  if (account_id) {
    data.relationships.account = {
      data: { type: 'accounts', id: parseInt(account_id) }
    }
  } else {
    data.relationships.account = {
      data: { type: 'accounts', id: '' }
    }
  }
  let path = '/contacts'
  if (personID) {
    path = `${path}/${personID}`
    return doPatch({ useChatURL: true, data, path })
  } else {
    return doPost({ useChatURL: true, data, path })
  }
}

function createContact ({ personAttributes }) {
  const path = '/contacts'
  const data = {
    type: 'contacts',
    attributes: personAttributes,
    relationships: {}
  }

  return doPost({
    path,
    useChatURL: true,
    data
  })
}

function getLiveView () {
  return doGet({ useChatURL: true, path: '/live_view' })
}

function getTrackingParameters ({ personID }) {
  const path = '/tracking/utm_parameters'
  const data = {
    id: personID,
    attributes: {
      person_id: personID
    }
  }

  return doPost({
    useChatURL: true,
    path,
    data
  })
}

function getContactInsights ({ id, question }) {
  const data = {
    attributes: {
      id,
      question
    }
  }
  return doStreamFetch({ path: '/api/core/ai/contact_insights', data })
}

export {
  contactSearch,
  exportContacts,
  deleteContacts,
  getContacts,
  countContacts,
  getContact,
  saveContact,
  createContact,
  getLiveView,
  getTrackingParameters,
  getContactInsights
}
