import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Radio } from 'library/materialUI'
import { Modal, ModalSection } from 'library/Modal'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

const useStyles = makeStyles(theme => ({
  dropdownOption: {
    display: 'flex',
    width: '100%'
  },
  iconButton: {
    height: 32,
    width: 32
  },
  menuIcon: {
    color: '#8E5AE2',
    paddingRight: 20
  },
  selectDiv: {
    marginTop: 10,
    width: '100%'
  },
  addDropDownBtn: {
    padding: 0
  }
}))

function DropdownOption (props) {
  const classes = useStyles()

  function handleChange (event) {
    const value = event.target.value
    props.handler(value)
  }

  function remapEnterKey (event) {
    if (event.key === 'Enter') {
      props.addOption()
    }
  }

  return (
    <div className={classes.dropdownOption}>
      <TextField
        autoFocus
        margin='dense'
        variant='outlined'
        fullWidth
        value={props.value}
        onChange={handleChange}
        onKeyPress={remapEnterKey}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                className={classes.iconButton}
                onClick={props.removeOption}
              >
                <HighlightOffIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}

function mapValueToDisplay (value) {
  if (value === 'unchosen') {
    return '--- Select a data type ---'
  } else if (value === 'date-time') {
    return 'Date and Time'
  } else if (value === 'true-false') {
    return 'True or False'
  } else {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

function CustomFieldsModal (props) {
  const [showNext, setShowNext] = useState(false)
  const classes = useStyles()

  const validationSchema = Yup.object().shape({
    label: Yup.string()
      .required('Please enter a label for your field')
  })
  let startingValues
  let mainTitle

  useEffect(() => {
    setShowNext(false)
    // eslint-disable-next-line
  }, [props.field])

  if (props.formProps) {
    mainTitle = 'Create New Field'
    startingValues = {
      label: '',
      description: '',
      type: props.formProps.value,
      options: [],
      slug: ''
    }
  } else if (props.field == null) {
    mainTitle = 'Create New Field'
    startingValues = {
      label: '',
      description: '',
      type: 'unchosen',
      options: [],
      slug: ''
    }
  } else {
    mainTitle = 'Edit Field'
    startingValues = {
      label: props.field.label,
      description: props.field.description,
      type: props.field.type,
      options: props.field.options,
      slug: props.field.slug
    }
  }

  const title = showNext ? 'Add Options' : mainTitle

  function isDisabled (values) {
    if (showNext) {
      if (values.options.length < 2) {
        return true
      }
      if (values.options.filter(o => o === '').length > 0) {
        return true
      }
      return false
    }
    return (values.label === '' || values.type === 'unchosen')
  }

  return (
    <Formik
      initialValues={startingValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        props.save(values)
        props.onHide()
        resetForm()
        setShowNext(false)
      }}
    >
      {({ submitForm, setFieldValue, resetForm, values }) => {
        /** @type {Parameters<Modal>[0]['rightButtonProps']} */
        function handleSubmit () {
          if ((values.type === 'dropdown' || values.type === 'multi' || values.type === 'radio') && !showNext) {
            setShowNext(true)
          } else {
            submitForm()
          }
        }
        return (
          <Modal
            onHide={() => {
              props.onHide()
              setTimeout(() => {
                resetForm()
                setShowNext(false)
              }, 25)
            }}
            open={props.open}
            size='sm'
            title={title}
            saveDisabled={isDisabled(values)}
            handleSave={handleSubmit}
            saveBtnText={props.field ? 'Save Field' : 'Create Field'}
            saveIcon='add'
          >
            {!showNext ? (
              <div>
                <ModalSection
                  title='Field Name'
                >
                  <TextField
                    name='label'
                    fullWidth
                    variant='outlined'
                    placeholder='Field name'
                    margin='dense'
                    value={values.label}
                    onChange={e => setFieldValue('label', e.target.value)}
                    className={classes.fieldName}
                  />
                </ModalSection>
                <ModalSection
                  title='Description'
                >
                  <TextField
                    name='description'
                    fullWidth
                    multiline
                    placeholder='Type a short description here'
                    rows={3}
                    variant='outlined'
                    margin='dense'
                    value={values.description}
                    onChange={e => setFieldValue('description', e.target.value)}
                    className={classes.fieldName}
                  />
                </ModalSection>
                <ModalSection
                  title='Field Type'
                >
                  <div className={classes.selectDiv}>
                    <FormControl variant='outlined' fullWidth>
                      <Select
                        id='field-type'
                        name='type'
                        value={values.type}
                        renderValue={value => {
                          return (mapValueToDisplay(value))
                        }}
                        onChange={value => {
                          setFieldValue('type', value)
                        }}
                        placeholder='Select a data type'
                        options={[
                          { value: 'text', label: 'Text' },
                          { value: 'numeric', label: 'Numeric' },
                          { value: 'date', label: 'Date' },
                          { value: 'date-time', label: 'Date and Time' },
                          { value: 'true-false', label: 'True or False' },
                          { value: 'dropdown', label: 'Dropdown' },
                          { value: 'multi', label: 'Multi Select' },
                          { value: 'radio', label: 'Radio' }
                        ]}
                      />
                    </FormControl>
                  </div>
                </ModalSection>
              </div>
            ) : (
              <div>
                <ModalSection>
                  {values.options.map((option, index) => {
                    return (
                      <DropdownOption
                        key={index}
                        value={option}
                        index={index}
                        handler={value => {
                          const options = JSON.parse(JSON.stringify(values.options))
                          options[index] = value
                          setFieldValue('options', options)
                        }}
                        removeOption={() => {
                          const options = JSON.parse(JSON.stringify(values.options))
                          options.splice(index, 1)
                          setFieldValue('options', options)
                        }}
                        addOption={() => {
                          const options = JSON.parse(JSON.stringify(values.options))
                          options.push('')
                          setFieldValue('options', options)
                        }}
                      />
                    )
                  })}

                  <div style={{ display: 'grid', gridTemplateColumns: '3fr 2fr', gridGap: 10, paddingTop: 25 }}>
                    {values.type === 'dropdown' && (
                      <Select
                        placeholder='Dropdown Preview'
                        name='type'
                        defaultValue='preview'
                        options={[
                          ...values.options.map((option, index) => ({ value: index, label: option }))
                        ]}
                        onChange={value => null}
                      />
                    )}
                    <Button
                      variant='outlined'
                      fullWidth
                      color='primary'
                      onClick={() => {
                        const options = JSON.parse(JSON.stringify(values.options))
                        options.push('')
                        setFieldValue('options', options)
                      }}
                      className={classes.addDropDownBtn}
                    >
                      Add {values.type} option
                    </Button>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: values.type === 'radio' ? '10px' : '0px' }}>
                    {values.type === 'radio' && (
                      values.options.filter(option => option !== '').map((option, index) => {
                        return (
                          <Radio
                            key={index}
                            label={option}
                            value={index === 0}
                            checked={index === 0}
                            handleChange={() => null}
                          />
                        )
                      })
                    )}
                  </div>
                </ModalSection>
              </div>
            )}
          </Modal>
        )
      }}
    </Formik>
  )
}

export default CustomFieldsModal
