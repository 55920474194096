import Bot from 'classes/bots'
import { ObjectDependency, ObjectDependencyChildTypes } from 'classes/objectDependency'
import { SearchParams } from 'classes/queryHelpers'
import Loader from '../loading/Loader'
import { Typography } from '../materialUI'
import { ObjectDependencyListItem } from './ObjectDependencyListItem'
import Form from 'classes/forms'

interface ObjectDependencyMessageProps {
  objectDependencyList: ObjectDependency[]
  objectType: ObjectDependencyChildTypes
}

const constructSearchParams = (objectDependencyList: ObjectDependency[]): SearchParams => {
  const botIDs: number[] = []
  objectDependencyList.forEach(od => { if (od.parentType === 'bot') { botIDs.push(parseInt(od.parentID)) } })
  const botSearchParams: SearchParams = botIDs.length
    ? {
      filters: [
        [{ field: 'id', operator: 'in', value: botIDs }]
      ]
    }
    : {}
  return botSearchParams
}

export default function ObjectDependencyMessage ({ objectDependencyList, objectType }: ObjectDependencyMessageProps) {
  const botSearchParams = constructSearchParams(objectDependencyList)
  const { data: botData, isLoading: botIsLoading, error } = Bot.loadAll({ searchParams: botSearchParams })
  const { data: formData, isLoading: formIsLoading } = Form.loadAll()

  if (botIsLoading || formIsLoading) {
    return (
      <Loader />
    )
  }

  if (error) {
    return (
      <div>Unable to find the relationship data for this object</div>
    )
  }

  const parentDict = {
    bot: botData.dict,
    form: {}
  }

  const formBots = Object.entries(botData.dict)
    .filter(([_, bot]) => bot.kind === 'Form Bot')
    .map(([botID, _]) => botID);

  formBots.forEach(botID => {
    const formID = Object.keys(formData.dict).find(id =>
      formData.dict[id].calendarBotID === Number(botID) || formData.dict[id].liveChatBotID === Number(botID) || formData.dict[id].offlineBotID === Number(botID)
    );

    if (formID) {
      parentDict.form[formID] = formData.dict[formID];

      objectDependencyList = objectDependencyList.map(od => {
        if (od.parentID === botID && od.parentType === 'bot') {
          return {
            ...od,
            parentID: formID,
            parentType: 'form'
          };
        }
        return od;
      });
    }
  });

  return (
    <div>
      <Typography>
        To delete this {objectType} remove its reference in the following:
      </Typography>
      {objectDependencyList.map(od => {
        return (
          <ObjectDependencyListItem
            objectDependency={od}
            parentDict={parentDict}
            key={`${od.parentType}-${od.parentID}`}
          />
        )
      })}
    </div>
  )
}
