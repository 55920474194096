import { useQuery } from 'react-query'
import { getConversationList } from 'api/conversations'
import { useDoQuery } from './useDoQuery'
import { doPatch, doDelete, doPost, doPut, doGet } from 'api/api'
import { GenericObject } from './genericObject'
import { RecentActivity } from './recentActivity'
import { castToDate, DateType } from './classHelpers'
import { LoadAllProps } from './queryHelpers'
import { pick } from 'lodash'

export class BotSummary extends GenericObject {
  prompts: number
  conversations: number
  emailsCaptured: number
  meetingsBooked: number
  liveChats: number
  primaryGoals: number
  engagement: number

  get engagementRate (): string {
    const rate = (this.engagement * 100).toFixed(2).toString() + '%'
    return rate
  }

  get conversationRate (): string {
    if (!this.conversations || !this.prompts) {
      return '0.00%'
    }
    const rate = ((this.conversations / this.prompts) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get emailRate (): string {
    if (!this.emailsCaptured || !this.conversations) {
      return '0.00%'
    }
    const rate = ((this.emailsCaptured / this.conversations) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get meetingBookedRate (): string {
    if (!this.meetingsBooked || !this.conversations) {
      return '0.00%'
    }
    const rate = ((this.meetingsBooked / this.conversations) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get routeRate (): string {
    if (!this.liveChats || !this.conversations) {
      return '0.00%'
    }
    const rate = ((this.liveChats / this.conversations) * 100).toFixed(2).toString() + '%'
    return rate
  }

  get primaryGoalRate (): string {
    if (!this.primaryGoals || !this.conversations) {
      return '0.00%'
    }
    const rate = ((this.primaryGoals / this.conversations) * 100).toFixed(2).toString() + '%'
    return rate
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const summary = row.attributes
    this.prompts = summary.prompts ? summary.prompts : 0
    this.conversations = summary.conversations ? summary.conversations : 0
    this.emailsCaptured = summary.emails_captured ? summary.emails_captured : 0
    this.meetingsBooked = summary.meetings_booked ? summary.meetings_booked : 0
    this.liveChats = summary.live_chats ? summary.live_chats : 0
    this.primaryGoals = summary.primary_goals ? summary.primary_goals : 0
    this.engagement = summary.engagement_rate ? summary.engagement_rate : 0
  }
}

const getBotType = (kind: string): string => {
  let type = kind + ' Bot'
  if (kind === 'team_live_chat_router') {
    type = 'Live Chat Router Bot'
  } else if (kind === 'content_page') {
    type = 'Conversational Landing Page'
  } else if (kind === 'abe_greeting') {
    type = 'ABE Greeting'
  }
  type = type.charAt(0).toUpperCase() + type.slice(1)
  return type
}

interface BotList {
  list: Bot[]
  dict: { [id: Bot['id']]: Bot }
}

type SaveProps = {
  bot: Bot
  publish?: boolean
}
type SaveBotFunction = ({ bot, publish }: SaveProps) => Promise<any>

type BotPromptSize = 'small' | 'med' | 'full'
type BotFrequency = 'everytime' | 'per_session' | 'visitor'
const dbProps = ['name', 'description', 'channelIDs', 'edges', 'nodes', 'enabled', 'clickSelector', 'fragment']
export class Bot extends GenericObject {
  kind: string
  readonly updatedUserID: number
  enabled: boolean
  fragment: any
  clickSelector: string
  flowID: number
  description: string
  activeTestID: number | null
  deletedTimestamp: DateType
  objectType = 'bot'
  edges: any[]
  nodes: any[]
  promptSize: BotPromptSize
  frequency: BotFrequency
  isActive: boolean
  readonly lastEditedTimestamp: DateType

  get link (): string { return `/bots/${this.id}` }
  get subtitle (): string { return this.kind }
  get searchField (): string { return this.name }
  get disabled (): boolean { return this.kind === 'Conversational Landing Page' }
  get disabledMessage (): string { return "To turn on conversational content bots, copy the link in the bot's settings and add it to your website." }

  static loadAll (props?: LoadAllProps): { data: BotList, isLoading: boolean, error: Error } {
    return useDoQuery({
      useChatURL: true,
      path: '/bots',
      objectClass: Bot,
      searchParams: props?.searchParams
    })
  }

  static loadOne (ID: Bot['id']): { data: Bot, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/bots/${ID}`, objectClass: Bot })
  }

  static loadRecentActivities (ID: Bot['id']): { data:any, isLoading:boolean } {
      const conversationFilters = { kind: 'search', values: { filter: { bot_ids: [ID] } } }
      const queryFunction = (): Promise<any> => {
        return getConversationList({ conversationFilters, limit: 4 })
          .then(response => {
            return response.data.map((row: any) => {
              const atts = row.attributes
              return new RecentActivity({
                name: atts.name,
                url: `/#/chat/${row.id}`,
                summary: atts.last_message,
                avatar: atts.contact_avatar,
                timestamp: atts.last_message_timestamp
              })
            })
          })
      }
      const key = `bots.recent_activities.${ID}`
      const { data, isFetched, isFetching, isError, error } = useQuery(key, queryFunction, {
        staleTime: 120000,
        retry: false,
        placeholderData: []
      })

      const isLoading = isFetching && !isFetched
      return { data: { list: data }, isLoading, isError, error }
  }

  static loadStats (ID: Bot['id']): { data: any, isLoading: boolean } {
    return useDoQuery({ useChatURL: true, path: `/bots/${ID}/report`, objectClass: BotSummary })
  }

  static getRosterData (ID: number): Promise<any> {
    return doGet({ path: `/bots/roster/${ID}`, useChatURL: true })
  }

  static update (ID: Bot['id'], updates: Partial<Bot>): Promise<any> {
    const path = `/bots/${ID}`

    const data = {
      type: 'bots',
      id: ID,
      attributes: updates
    }
    return doPatch({ path, data, useChatURL: true })
  }

  static save: SaveBotFunction = ({ bot, publish }) => {
    const attributes = pick(bot, dbProps)
    const data = {
      type: 'bots',
      id: bot.id,
      attributes
    }

    if (bot.id) {
      const path = publish ? `/bots/${bot.id}:publish` : `/bots/${bot.id}`
      return doPut({ path, data, useChatURL: true })
    } else {
      const path = '/bots'
      return doPost({ path, data, useChatURL: true })
    }
  }

  static deleteBot (ID: Bot['id']): Promise<any> {
    const path = `/bots/${ID}`
    return doDelete({ path, useChatURL: true })
  }

  delete (): Promise<any> {
    const path = `/bots/${this.id}`
    return doDelete({ path: path, useChatURL: true })
  }

  archive (): Promise<any> {
    const path = `/bots/archive/${this.id}`
    return doPost({ path: path, useChatURL: true })
  }

  async clone (): Promise<string> {
    const path = `/bots/${this.id}:clone`
    return doPost({ path: path, useChatURL: true }).then(
      (response: any) => {
        return '/bots/' + response.data.id
      }
    )
  }

  static create (): void {
    window.open('/#/bots/new')
  }

  static edit (id: number): void {
    window.open(`/#/bots/${id}`)
  }

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const bot = row.attributes
    this.kind = getBotType(bot.kind)
    this.deletedTimestamp = castToDate(bot.deleted_timestamp)
    this.updatedUserID = bot.updated_by_user_id
    this.enabled = bot.enabled
    this.fragment = bot.fragment
    this.description = bot.description
    this.clickSelector = bot.click_selector
    this.flowID = bot.flow_id
    this.activeTestID = bot.active_test_id
    this.edges = bot.edges
    this.nodes = bot.nodes
    this.promptSize = bot.prompt_size || 'med'
    this.frequency = bot.frequency || 'everytime'
    this.delete = this.delete.bind(this)
    this.lastEditedTimestamp = bot.updated_timestamp
    this.isActive = bot.is_active ? bot.is_active : false
  }
}

export default Bot
