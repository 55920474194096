/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useContext, useEffect, SetStateAction, Dispatch } from 'react'
import {
  Redirect,
  useHistory,
  useLocation
} from 'react-router-dom'
import { SessionContext } from '../../session-context'
import RegistrationTemplate from './RegistrationTemplate'
import LoginSideBar from './LoginSideBar'
import { useQueryClient } from 'react-query'
import { getSearchParam } from 'helpers/strings'
import { Location } from 'history'

const initialEmailPassword = { email: '', password: '' }

const ssoErrorsDict = {
  '#unableToFindUser': 'There is no user with this email address in our system'
}

export default function LoginPage () {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const { setUser, setSubscriptionStatus } = useContext(SessionContext)
  const queryClient = useQueryClient()
  const [mfaCoupon, setMfaCoupon] = useState(null)
  const [mfaSetup, setMfaSetup] = useState(false)
  const [multipleTenants, setMultipleTenants] = useState([])
  const [emailPassword, setEmailPassword] = useState(initialEmailPassword)
  const [ssoErrors, setSSOErrors] = useState('')
  const history = useHistory()
  const location = useLocation()
  const { from } = location.state as { from: { pathname: string } } || { from: { pathname: '/dashboard' } }
  HandleErrorsFromSSORedirect({ location, setSSOErrors })

  function performLoginMFA (values: { mfa_code: any, mfa_coupon: any }) {
    fetch('/api/auth/mfa', {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({ mfa_code: values.mfa_code, mfa_coupon: values.mfa_coupon, remember_device: false })
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          queryClient.invalidateQueries()
          setLoggedIn(true)
          setUser(response.data)
          history.replace(from)
        } else {
          setLoginErrorMessage('Unable to login')
        }
      })
  }
  const tenantBundle = location.state?.tenantBundle || {}

  function performMFA (values: any, actions: any) {
    fetch('/api/auth/mfa', {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({ mfa_code: values.mfa_code, mfa_coupon: mfaCoupon, remember_device: values.remember_device || false })
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setLoggedIn(true)
          setUser(response.data)
          history.replace(from)
        } else {
          actions.setStatus({
            mfa_code: response.message
          })
          setLoginErrorMessage('Unable to login')
        }
      })
  }

  function performLogin (values: any, actions: any) {
    actions.setSubmitting(true)
    const endpoint = getEndpoint(location, values)
    setEmailPassword({ email: values.email, password: values.password })
    fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/vnd.api+json' },
      body: JSON.stringify({ email: values.email, password: values.password, onlyForTenantId: tenantBundle.t })
    })
      .then(response => {
        if (response.status === 401) {
          return {
            success: false,
            message: 'Invalid email or password'
          }
        }
        return response.json()
      })
      .then(response => {
        if (response?.meta?.billing_status) {
          setSubscriptionStatus(response.meta.billing_status)
        }
        if (response.data && response.data.id) {
          setLoggedIn(true)
          setUser(response.data)
          setEmailPassword(initialEmailPassword)
          history.replace(from)
        } else if (response && (!response.success) && response.set_up_mfa && response.mfa_coupon) {
          setMultipleTenants([])
          setMfaCoupon(response.mfa_coupon)
          setMfaSetup(true)
        } else if (response && (!response.success) && response.mfa_coupon) {
          setMultipleTenants([])
          setMfaCoupon(response.mfa_coupon)
        } else if (response.data && response.data.tenants) {
          setMultipleTenants(response.data.tenants)
        } else if (response.url) {
          window.location.href = response.url
        } else {
          let errMessage = 'Unable to login'
          if (response?.message) {
            errMessage = response.message
          } else if (response?.errors?.length > 0) {
            errMessage = response.errors[0].title + ', ' + response.errors[0].detail
          }
          actions.setStatus({
            password: errMessage,
            type: response.type || ''
          })
          setLoginErrorMessage('Unable to login')
        }
      }).finally(() => {
        actions.setSubmitting(false)
      })
  }

  if (loggedIn) {
    setLoggedIn(false)
    return <Redirect to='/' />
  }

  return (
    <RegistrationTemplate>
      <LoginSideBar
        onMFASubmit={performMFA}
        onLoginMFASubmit={performLoginMFA}
        onSubmit={performLogin}
        loginErrorMessage={loginErrorMessage}
        ssoErrors={ssoErrors}
        mfaCoupon={mfaCoupon}
        mfaSetup={mfaSetup}
        tenantBundle={tenantBundle}
        multipleTenants={multipleTenants}
        emailPassword={emailPassword}
      />
    </RegistrationTemplate>
  )
}

const getEndpoint = (location: Location, values: { tenant_id: number }): string => {
  const oauth = getSearchParam(location.search, 'oauth')
  let endpoint = '/api/auth/login'
  if (oauth) {
    return `/api/auth/login/oauth/${oauth}`
  }
  endpoint += values.tenant_id ? `/${values.tenant_id}` : '/0'
  return endpoint
}

function HandleErrorsFromSSORedirect ({ location, setSSOErrors }: { location: Location, setSSOErrors: Dispatch<SetStateAction<string>> }) {
  useEffect(() => {
    if (location.hash && location.hash in ssoErrorsDict) {
      const hash = location.hash as keyof typeof ssoErrorsDict
      setSSOErrors(ssoErrorsDict[hash])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}
