import { useDoQuery, useDoTypeQuery } from './useDoQuery'
import { DateType, castToDate } from './classHelpers'
import { GenericObject } from './genericObject'
import { isEmpty } from 'lodash'
import { getEmployeeRange, getRevenueRange } from 'pages/accounts/definedFields';
import { LoadAllProps, SearchParams } from './queryHelpers';
import { doGet } from 'api/api';

export const mostRecentFilter: SearchParams = {
  sortOrder: 'desc',
  sortColumn: 'last_activity_timestamp',
  pageSize: 500
}

export const Accountfilters: Record<string, SearchParams> = {
  mostRecent: mostRecentFilter
}

const defaultState = {
  domain: '',
  website: '',
  logo_url: '',
  owner_user_id: '',
  source_integration_name: '',
  created_timestamp: '',
  deleted_timestamp: '',
  tenant_id: '',
  phone: '',
  account_type: '',
  employee_count: '',
  employee_count_range: '',
  annual_revenue: '',
  annual_revenue_range: '',
  industry: '',
  description: '',
  address_city: '',
  address_state: '',
  address_postal_code: '',
  address_country_code: '',
  country: '',
  snoozed_until_timestamp: '',
  technologies: '',
  linked_in: '',
  twitter_url: '',
  facebook_url: '',
  total_funding: '',
  last_funding_amount: '',
  last_funding_date: '',
  crm_link: ''
}

export interface AccountList {
  list: Account[]
  dict: { [id: Account['id']]: Account }
  meta?: {
    page?: { total?: number }
  }
}

export default class Account extends GenericObject {
  objectType = 'account'
  domain: string
  website: string
  logoUrl: string
  ownerUserId: number
  sourceIntegrationName: string | null
  createdTimestamp: DateType
  deletedTimestamp: DateType
  tenantId: number
  phone: string
  accountType: string
  employeeCount: number
  employeeCountRange: string | null
  annualRevenue: number
  annualRevenueRange: string | null
  industry: string | null
  description: string
  addressCity: string
  addressState: string
  addressPostalCode: string
  addressCountryCode: string
  country: string
  snoozedUntilTimestamp: DateType
  technologies: Array<string>
  linkedIn: string
  twitterURL: string
  facebookURL: string
  totalFunding: number
  lastFundingAmount: number
  lastFundingDate: string
  crmLink: string
  crmRecordType: string
  engagementScore: number
  opportunityStatus: string
  contacts: number
  oppAmountCents: number
  companyIsICP: boolean

  get link (): string { return `/accounts/${this.domain}` }
  get subtitle (): string { return this.domain }
  get revenueRange (): string { return getRevenueRange(this.annualRevenue) }
  get employeeRange (): string { return getEmployeeRange(this.employeeCount) }
  get avatarUrl (): string { return `/api/logo-service/logo/${this?.domain}.png` }
  static get selectedField (): string { return 'id' }

  static createPath = (endpoint: string, segmentID?: number): string => {
    const params = {} as Record<string, number>
    if (segmentID) {
      params['filter[segment_id]'] = segmentID
    }
    if (!isEmpty(params)) {
      return `${endpoint}?` + Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
    }
    return endpoint
  }

  static loadAll = (loadAllProps?: LoadAllProps, segmentID?: number): { data: AccountList, isLoading: boolean } => {
    const path = Account.createPath('/accounts', segmentID)

    if (!loadAllProps) {
      loadAllProps = { searchParams: { pageSize: 100, pageNumber: 1, queryParams: {}, search: '', sortColumn: 'name', sortOrder: 'asc' } }
    }
    return useDoQuery({
      path,
      useChatURL: true,
      objectClass: Account,
      initialData: { list: [], dict: {} },
      searchParams: loadAllProps?.searchParams
    })
  }

  static loadOne (id: number | string): { data: Account, isLoading: boolean, error: Error } {
    return useDoQuery({ path: `/accounts/${id}`, useChatURL: true, objectClass: Account })
  }

  static loadOneByDomain (domain: string): { data: Account, isLoading: boolean, error: Error } {
    return useDoQuery({ path: `/accounts/domain/${domain}`, useChatURL: true, objectClass: Account })
  }

  static loadCount (loadAllProps?: LoadAllProps, segmentID?: number): { data: number | number[], isLoading: boolean } {
    const path = Account.createPath('/accounts:count', segmentID)

    return useDoTypeQuery({
      path: path,
      useChatURL: true,
      searchParams: loadAllProps?.searchParams,
      keepPreviousData: true
    })
  }

  static download (searchParams?: SearchParams, segmentID?: number) {
    const path = Account.createPath('/core/csv_download/Account', segmentID)
    return doGet({ path: path, searchParams: searchParams, returnText: true })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    super({ row })
    const attr: Record<string, any> = row?.attributes || defaultState
    this.domain = attr.domain
    this.website = attr.website
    this.logoUrl = attr.logo_url
    this.ownerUserId = attr.owner_user_id
    this.sourceIntegrationName = attr.source_integration_name
    this.createdTimestamp = castToDate(attr.created_timestamp)
    this.deletedTimestamp = castToDate(attr.deleted_timestamp)
    this.tenantId = attr.tenant_id
    this.phone = attr.phone
    this.accountType = attr.account_type
    this.employeeCount = parseInt(attr.employee_count)
    this.employeeCountRange = attr.employee_count_range
    this.annualRevenue = parseInt(attr.annual_revenue)
    this.annualRevenueRange = attr.annual_revenue_range
    this.industry = attr.industry
    this.description = attr.description
    this.addressCity = attr.address_city
    this.addressState = attr.address_state
    this.addressPostalCode = attr.address_postal_code
    this.addressCountryCode = attr.address_country_code
    this.country = attr.country
    this.snoozedUntilTimestamp = castToDate(attr.snoozed_until_timestamp)
    this.technologies = attr.technologies
    this.linkedIn = attr.linked_in
    this.twitterURL = attr.twitter_url
    this.facebookURL = attr.facebook_url
    this.totalFunding = attr.total_funding
    this.lastFundingAmount = attr.last_funding_amount
    this.lastFundingDate = attr.last_funding_date
    this.crmLink = attr.crm_link
    this.crmRecordType = attr.crm_record_type
    this.engagementScore = attr.engagement_score
    this.opportunityStatus = attr.opportunity_status
    this.contacts = attr.contacts
    this.oppAmountCents = attr.opp_amount_cents
    this.companyIsICP = attr.company_is_icp
  }
}
