
import { getEmployeeRange, getRevenueRange } from 'pages/accounts/definedFields';
import { castToDate, DateType } from './classHelpers';
import Company from './companies';
import { QueryFilter, QueryFilterList, SearchParams, LoadAllProps } from './queryHelpers';
import { useDoQuery, useDoTypeQuery } from './useDoQuery';

export interface SummaryCompaniesList {
  list: SummaryCompanies[]
  dict: { [id: SummaryCompanies['id']]: SummaryCompanies }
  meta: { total: number }
}

const GeneralFilter: QueryFilter = { field: 'id', operator: 'is not', value: null }
const SnoozeFilter: QueryFilter = { field: 'snoozed_until_timestamp', operator: 'eq', value: null }
const ExcludeClosedAccountsFilter: QueryFilter = { field: 'is_closed', operator: 'eq', value: false }
const TrendingFilter: QueryFilter = { field: 'engagement_score', operator: 'gt', value: 0 }
export const GeneralFilterList: QueryFilterList = [[GeneralFilter]]
export const GeneralFilterTrendingList: QueryFilterList = [[GeneralFilter, ExcludeClosedAccountsFilter, TrendingFilter]]
export const AdminFilterTrending: QueryFilterList = [[GeneralFilter, SnoozeFilter, ExcludeClosedAccountsFilter, TrendingFilter]]
export const SalesFilterTrending = (user_id: number): QueryFilterList => [[GeneralFilter, SnoozeFilter, TrendingFilter, { field: 'owner_user_id', operator: 'eq', value: user_id }, ExcludeClosedAccountsFilter]]
export const AdminFilter: QueryFilterList = [[GeneralFilter, SnoozeFilter]]
export function SalesFilter (user_id: number): QueryFilterList { return [[GeneralFilter, SnoozeFilter, { field: 'owner_user_id', operator: 'eq', value: user_id }]] }
export const PotentialFilter: QueryFilterList = [[{ field: 'id', operator: 'is', value: null }]]

interface SummaryLoadAllProps extends LoadAllProps {
  // searchParams: SearchParams & { filters: typeof AdminFilter | typeof SalesFilter | typeof PotentialFilter | typeof GeneralFilterList }
  searchParams: SearchParams
}
export class SummaryCompanies extends Company {
  objectType = 'summaryCompanies'
  conversations: number
  meetings: number
  contacts: number
  recentContacts: number
  engagementScore: number
  location: string
  pageViews: number
  siteVisits: number
  visitors: number
  lastActivityTimestamp: DateType
  oppAmount: number
  isClosed: boolean
  isWon: boolean
  oppType: string
  ownerUserID: number
  crmLink: string
  sourceIntegrationName: string
  accountType: string
  isICP: boolean
  trafficSources: string[]
  sourceDomains: string[]
  crmRecordType: string
  highIntentPageIds: string[]
  segments: number[]
  engagementScoreChange: number
  isAccount: boolean
  sourceTypes: string[]
  referrers: string[]
  trafficCampaigns: string[]

  constructor ({ row }: { row: Record<string, any> }) {
    super({ row })
    const summary = row.attributes
    this.visitors = summary.visitors
    this.pageViews = summary.page_views
    this.siteVisits = summary.site_visits
    this.lastActivityTimestamp = castToDate(summary.last_activity)
    this.conversations = summary.conversations
    this.meetings = summary.meetings
    this.contacts = summary.contacts
    this.recentContacts = summary.recent_contacts
    this.location = summary.location
    this.engagementScore = summary.engagement_score
    this.opportunityStatus = summary.opportunity_status
    this.oppAmount = summary.opp_amount_cents
    this.isClosed = summary.is_closed
    this.isWon = summary.is_won
    this.oppType = summary.opp_type
    this.ownerUserID = summary.owner_user_id
    this.crmLink = summary.crm_link
    this.sourceIntegrationName = summary.source_integration_name
    this.accountType = summary.account_type
    this.isICP = summary.company_is_icp
    this.trafficSources = summary.traffic_sources
    this.sourceDomains = summary.source_domains
    this.crmRecordType = summary.crm_record_type
    this.highIntentPageIds = summary.high_intent_page_ids
    this.segments = summary.segments
    this.engagementScoreChange = summary.engagement_score_change
    this.isAccount = summary.id !== null
    this.sourceTypes = summary.source_type
    this.referrers = summary.referrer
    this.trafficCampaigns = summary.traffic_campaigns
  }

  get revenueRange (): string { return getRevenueRange(this.annualRevenue) }
  get employeeRange (): string { return getEmployeeRange(this.numberOfEmployees) }
  get link (): string { return `/accounts/${this.domain}` }

  static loadAll (props?: SummaryLoadAllProps): { data: SummaryCompaniesList, isLoading: boolean } {
    return useDoQuery({
      path: '/companies/summary',
      searchParams: props?.searchParams,
      objectClass: SummaryCompanies,
      useChatURL: true,
      keepPreviousData: true
    })
  }

  static loadCount (props?: SummaryLoadAllProps): { data: number | number[], isLoading: boolean } {
    return useDoTypeQuery({
      path: '/companies/summary/count',
      searchParams: props?.searchParams,
      useChatURL: true,
      keepPreviousData: true
    })
  }

  static getName (): string {
    return 'filters'
  }

  static getSortOptions () {
    return [
      { value: 'last_activity_date', label: 'Last Visit' },
      { value: 'name', label: 'Company Name' },
      { value: 'crm_record_type', label: 'CRM Record Type' },
      { value: 'account_type', label: 'Account Type' },
      { value: 'site_visits', label: 'Site Visits' },
      { value: 'contacts', label: 'Contacts' },
      { value: 'opportunity_status', label: 'Opportunity Status' },
      { value: 'engagement_score', label: 'Signals Score' }
    ]
  }
}
