import { doPatch, doPost } from 'api/api'
import { camelCase, paramCase } from 'change-case'
import { useDoQuery } from './useDoQuery'

type LoadFunction = () => { settings: Record<string, any>, isLoading: boolean }

type UpdateParams = {
  page: string
  type: string
  settings: Record<string, any> | string
}

type UpdateFunction = ({ page, type, settings }: UpdateParams) => void

export class DisplaySettings {
  settings: Record<string, any>

  static load: LoadFunction = () => {
    const { data, isLoading } = useDoQuery({ path: '/user_display_settings', objectClass: DisplaySettings })
    return { settings: data.settings || {}, isLoading }
  }

  static create: UpdateFunction = ({ page, type, settings }) => {
    page = paramCase(page)
    const path = `/user_display_settings/${page}`
    const data = {
      attributes: {
        settings: {
          [type]: settings
        }
      }
    }
    return doPost({ path, data, silent: true })
  }

  static update: UpdateFunction = ({ page, type, settings }) => {
    page = paramCase(page)
    const path = `/user_display_settings/${page}`
    const data = {
      attributes: {
        settings: {
          [type]: settings
        }
      }
    }
    return doPatch({ path, data, silent: true })
  }

  constructor ({ row }: { row: Record<string, any> }) {
    const original = row.attributes.user_settings
    const settings: Record<string, any> = {}
    for (const key in original) {
      settings[camelCase(key)] = original[key]
    }
    this.settings = settings
  }
}
