import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  small: {
    width: 32,
    height: 32
  },
  large: {
    width: 50,
    height: 50
  }
}));
interface AvatarProps extends MuiAvatarProps {
  name?: string
  background?: string
  color?: string
  size?: 'sm' | 'lg'
}

function getInitials (name: string) {
  let initials = ''
  if (name) {
    const words = name.split(' ')
    if (words.length > 1) {
      initials = words[0].replace(/[^a-zA-Z]/g, '').charAt(0) + words[1].replace(/[^a-zA-Z]/g, '').charAt(0)
    } else if (words.length) {
      initials = words[0].replace(/[^a-zA-Z]/g, '').charAt(0)
    }
  }

  return (initials).toUpperCase()
}

export function Avatar (props: AvatarProps): JSX.Element {
  const classes = useStyles()
  const initials = props.name ? getInitials(props.name) : ''

  let className
  if (props.size === 'lg') {
    className = classes.large
  } else if (props.size === 'sm') {
    className = classes.small
  }

  return (
    <MuiAvatar
      style={{
        backgroundColor: props.background,
        color: props.color
      }}
      className={className}
      {...props}
    >
      {initials}
    </MuiAvatar>
  )
}
