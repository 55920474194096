import { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import twemoji from 'twemoji'
import sanitizeHTML from 'sanitize-html'
import linkifyHtml from 'linkifyjs/html'
import DOMPurify from 'dompurify'

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank')
  }
})

const useStyles = makeStyles(theme => ({
  preview: {
    padding: '10px 10px 10px 20px',
    borderLeft: '3px solid #aaa',
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 650,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)'
    }
  },
  closeIcon: {
    color: '#777',
    fontSize: '1em',
    cursor: 'pointer'
  }
}))

function LinkPreview (props) {
  const classes = useStyles()
  const [previewData, setPreviewData] = useState(null)
  const chatServiceUrl = props.chatServiceUrl
  let url
  const candidates = props.text.split(' ').filter(t => t.includes('href'))
  if (candidates.length) {
    const lastCandidate = candidates[candidates.length - 1]
    url = lastCandidate.split('"')[1]
    if (!url.includes('http')) {
      url = 'https://' + url
    }
  }

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/vnd.api+json',
      Pragma: 'no-cache'
    }
    setPreviewData(null)
    if (url) {
      fetch('/api/link-preview-service/preview?' + new URLSearchParams({ url: url }), {
        method: 'GET',
        header: headers
      }).then(response => response.json()).then(response => {
        if (response?.data?.attributes) {
          setPreviewData({
            title: response.data.attributes.title,
            description: response.data.attributes.description,
            image: response.data.attributes.image
          })
        }
      })
    }
  }, [chatServiceUrl, url])

  return (
    <div
      className={props.className}
      key={props.key}
    >
      <div
        ref={props.divRef}
        dangerouslySetInnerHTML={{ __html: props.text }}
        style={{ color: 'inherit' }}
      />
      {Boolean(previewData && previewData.description) &&
        <div style={{ position: 'relative' }}>
          {/* <div style={{ position: 'absolute', left: -20 }}>
            <CloseIcon className={classes.closeIcon} />
          </div> */}
          <a href={url} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className={classes.preview}>
              <div style={{ display: 'flex' }}>
                {previewData.image &&
                  <div style={{ maxHeight: 100, marginRight: 10 }}>
                    <img src={previewData.image} width={100} alt='link-preview' />
                  </div>}
                <div style={{ fontSize: '1em', fontWeight: 600 }}>
                  {previewData.title}
                </div>
              </div>
              <div style={{
                fontSize: '0.9em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical'
              }}
              >
                {previewData.description}
              </div>
            </div>
          </a>
        </div>}
    </div>
  )
}

function RenderEmoji (props) {
  const divRef = useRef()
  const emojiCountRef = useRef(0)

  useEffect(() => {
    twemoji.parse(divRef.current, {
      callback: (icon, options, variant) => {
        emojiCountRef.current = emojiCountRef.current + 1
        return ''.concat(options.base, options.size, '/', icon, options.ext)
      }
    })
    if (emojiCountRef.current === 1 && !divRef.current.innerText.trim()) {
      divRef.current.classList.add('big')
    }
  }, [props.text])

  const linked = linkifyHtml(props.text, { defaultProtocol: 'https' })
  if (linked.includes('<a href=') && !(linked.includes('mailto'))) {
    if (props.chatServiceUrl) {
      return (
        <LinkPreview
          className={props.className}
          text={DOMPurify.sanitize(linked)}
          divRef={divRef}
          chatServiceUrl={props.chatServiceUrl}
        />
      )
    }
  }

  if (props.rich) {
    return (
      <div
        className={props.className}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        ref={divRef}
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(
            props.text.replaceAll('<br>', '').replaceAll('<p>', '').replaceAll('</p>', '<br>').replaceAll('\t', '&nbsp;&nbsp;&nbsp;&nbsp;'),
            {
              allowedSchemes: ['http', 'https'],
              allowedSchemesByTag: {
                img: ['data']
              },
              allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img', 'h1', 'h2', 'h3', 'u', 's'])
            }
          )
        }}
      />
    )
  }

  const text = props.text
  // SplitOnEmails(text);

  return (
    <div className={props.className} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} ref={divRef}>{text}</div>
  )
}

export default RenderEmoji
