import { doDelete, doPatch } from 'api/api';
import GenericObject from './genericObject';
import { LoadAllProps } from './queryHelpers';
import { useDoQuery } from './useDoQuery';

export interface CustomFieldList {
    list: CustomField[];
    dict: { [id: CustomField['id']]: CustomField }
}

export type CustomFieldObjects = 'people' | 'accounts'

export default class CustomField extends GenericObject {
    label: string
    forObject: CustomFieldObjects
    kind: string
    description: string
    slug: string
    options: string[]

    static loadAll (props?: LoadAllProps, forObject?: CustomFieldObjects): { data: CustomFieldList, isLoading: boolean} {
        const path = '/custom_fields'
        if (forObject) {
            if (props?.searchParams) {
                props.searchParams.queryParams = {
                    for_object: forObject
                }
            } else {
                props = {
                    searchParams: {
                        queryParams: {
                            for_object: forObject
                        }
                    }
                }
            }
        }
        return useDoQuery({
            path,
            useChatURL: true,
            objectClass: CustomField,
            searchParams: props?.searchParams
        })
    }

    static delete (id: number): Promise<any> {
        const path = `/custom_fields/${id}`
        return doDelete({ path, useChatURL: true })
    }

    static save (data: any): Promise<any> {
        const path = `/custom_fields/${data.slug}`
        return doPatch({ path, useChatURL: true, data })
    }

    delete (): Promise<any> {
        const path = `/custom_fields/${this.id}`
        return doDelete({ path, useChatURL: true })
    }

    constructor ({ row }: { row: Record<string, any>}) {
        super({ row })
        const customField = row.attributes
        this.name = customField.label
        this.label = customField.label
        this.forObject = customField.for_object
        this.kind = customField.kind
        this.description = customField.description
        this.slug = customField.slug
        this.options = customField.options
    }
}
