import makeStyles from '@material-ui/styles/makeStyles'
import { ObjectDependency, ObjectDependencyNodeTypes } from 'classes/objectDependency'
import Loader from 'library/loading/Loader'
import { Tooltip, Typography } from 'library/materialUI'
import { Icon } from 'library/materialUI/Icon'

const useStyles = makeStyles((theme) => ({
  messageDiv: {
    display: 'grid',
    gridTemplateColumns: '10% 90%'
  },
  link: {
    textDecoration: 'underline'
  },
  parentLinkDiv: {
    cursor: 'pointer'
  },
  iconSpan: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0 5px'
  },
  iconRowSpan: {
    display: 'flex',
    alignItems: 'center'
  }
}))
interface ObjectDependencyListItemProps {
  objectDependency: ObjectDependency
  parentDict: any
}

const botNodes: Record<ObjectDependencyNodeTypes, { icon: JSX.Element }> = {
  'Calendar Invite': { icon: <Tooltip title='Calendar Invite'><div><Icon icon='calendar' color='black' /></div></Tooltip> }
}

const metaTypeMapping = {
  bot: {
    icon: <Icon icon='botHead' color='primary' size='sm' />,
    meta: botNodes,
    link: '/#/bots/',
    metaText: 'Skills referenced in:'
  },
  form: {
    icon: <Icon icon='formSubmitted' color='primary' size='sm' />,
    meta: botNodes,
    link: '/#/forms/',
    metaText: 'Skills referenced in:'
  }
}

function GetMetaIconsAndCount (objectDependency: ObjectDependency, meta: any) {
  const classes = useStyles()
  const listOfMetaObjs = objectDependency.meta
  const iconTracking: Record<string, { count: number, icon: JSX.Element }> = {}
  listOfMetaObjs.forEach((obj) => {
    if (obj.type in iconTracking) {
      iconTracking[obj.type].count = iconTracking[obj.type].count + 1
    } else {
      iconTracking[obj.type] = { count: 1, icon: meta[obj.type].icon }
    }
  })
  const iconArray: JSX.Element[] = []
  for (const property in iconTracking) {
    const { count, icon } = iconTracking[property]
    iconArray.push(<span className={classes.iconSpan}>{icon}{count}</span>)
  }
  return iconArray
}

export function ObjectDependencyListItem ({ objectDependency, parentDict } : ObjectDependencyListItemProps) {
  const parentType = objectDependency.parentType
  const classes = useStyles()
  if (!(parentType in metaTypeMapping)) {
    return (
      <></>
    )
  }

  if (!parentDict) {
    return <Loader />
  }

  const { icon, meta, link, metaText } = metaTypeMapping[parentType]
  const { name, id } = parentDict[parentType][objectDependency.parentID]
  const metaIcons = GetMetaIconsAndCount(objectDependency, meta)
  return (
    <div>
      <span
        className={`${classes.messageDiv} ${classes.parentLinkDiv}`}
        onClick={() => window.open(link + id)}
      >
        <span>
          {icon}
        </span>
        <Typography
          className={classes.link}
          color='primary'
        >
          {name}
        </Typography>
      </span>
      <div className={classes.messageDiv}>
        <span />
        <span className={classes.iconRowSpan}>{metaText}{metaIcons}</span>
      </div>
    </div>
  )
}
