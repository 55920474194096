import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Tooltip } from '@material-ui/core'
import Link from 'cf-components/Link'
import { getInitials, getColorByID } from 'cf-components/Avatars'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { useHistory } from 'react-router-dom'
import { fromProspectIcon, organicIcon, iqlIcon } from '../../pages/people/ContactTableFilters'
import { prospectToContactIcon } from '../../pages/accounts/AccountDashboard/AccountContacts'

const useStyles = makeStyles({
  container: {
    display: 'grid',
    alignItems: 'center',
    width: 'calc(100% - 10px)',
    gridTemplateColumns: '40px 1fr',
    gridGap: 10
  },
  nameParagraph: {
    margin: 0,
    fontSize: '1em',
    maxWidth: '230px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  emailParagraph: {
    margin: 0,
    fontSize: '.8em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  avatar: {
    height: 32,
    width: 32,
    cursor: 'pointer',
    fontSize: '1rem'
  },
  contactInfo: {
    minWidth: 0
  }
})

function AvatarImage (props) {
  const row = props.row
  const initials = getInitials(row.name)
  const avatarColor = getColorByID(row.id)
  const variant = props.variant || 'rounded'

  if (row.avatarUrl) {
    return (
      <Avatar
        src={row.avatarUrl}
        variant={variant}
      >
        {initials}
      </Avatar>
    )
  } else {
    return (
      <Avatar
        variant={variant}
        style={{ color: 'white', backgroundColor: avatarColor }}
      >
        {initials}
      </Avatar>
    )
  }
}

export function AvatarLink (props) {
  if (props.link) {
    return (
      <Link to={props.link} target={props.target || '_blank'}>
        {props.children}
      </Link>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

interface GroupedAvatarsProps {
  objects: {
    name: string,
    id: number,
    link: string,
    avatarUrl: string
  }[],
  max?: number,
  color?: string
}

export function GroupedAvatars ({ objects, max, color }: GroupedAvatarsProps) {
  const classes = useStyles()
  const newMax = max || 5
  const history = useHistory()
  const initObjectState = {
    name: '',
    id: 0,
    link: '',
    avatarUrl: ''
  }
  return (
    <AvatarGroup max={newMax} classes={{ avatar: classes.avatar }}>
      {objects.map(object => {
        if (!object) { object = initObjectState }
        const initials = getInitials(object.name)
        const avatarColor = color || getColorByID(object.id)
        return (
          <Tooltip title={object.name} key={object.id}>
            <Avatar
              style={{ backgroundColor: avatarColor }}
              onClick={() => history.push(object.link)}
              src={object.avatarUrl}
            >
              {initials}
            </Avatar>
          </Tooltip>
        )
      })}
    </AvatarGroup>
  )
}

export const sourceIconMapping = {
  prospect: fromProspectIcon,
  organic: organicIcon,
  iql: iqlIcon,
  prostocon: prospectToContactIcon,
  form: organicIcon
}

export function ObjectAvatar (props) {
  const row = props.row
  const classes = useStyles()
  const type = row?.constructor?.name
  const variant = type === 'User' ? 'circular' : 'rounded'
  const subtitle = props.title ? row.title : row.subtitle
  const style = props.onClick ? { cursor: 'pointer' } : {}
  const prospectIcon = 'contactType' in row ? sourceIconMapping[row.sourceIcon] : <></>
  const hasLink = props.hasLink
  const container = props.container ? props.container : classes.container

  if (hasLink) {
    return (
      <AvatarLink link={row.link}>
        <div
          className={container}
          onClick={props.onClick ? () => props.onClick(row) : null}
          style={style}
        >
          <div>
            <AvatarImage
              row={row}
              variant={variant}
            />
          </div>
          <div className={classes.contactInfo}>
            <div style={{ display: 'flex' }}><h3 className={classes.nameParagraph}>{row.name}</h3><div style={{ paddingLeft: 10 }}>{prospectIcon}</div></div>
            {Boolean(subtitle) &&
              <p className={classes.emailParagraph}>{subtitle}</p>}
          </div>
        </div>
      </AvatarLink>
    )
  }

  return (
    <div
      className={container}
      onClick={props.onClick ? () => props.onClick(row) : null}
      style={style}
    >
      <div>
        <AvatarImage
          row={row}
          variant={variant}
        />
      </div>
      <div
        className={classes.contactInfo}
        style={props.container ? { marginLeft: 10 } : {}}
      >
        <div style={{ display: 'flex' }}><h3 className={classes.nameParagraph}>{row.name}</h3><div style={{ paddingLeft: 10 }}>{prospectIcon}</div></div>
        {Boolean(subtitle) &&
          <p className={classes.emailParagraph}>{subtitle}</p>}
      </div>
    </div>
  )
}
