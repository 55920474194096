import { useContext, useEffect, useReducer, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeleteModal from 'components/DeleteModal'
import CalendarPage from './Calendars/MyCalendarPage'
import MyProfile from '../users/MyProfile'
import Notifications from './Notifications'
import { SessionContext, components } from 'session-context'
import { saveUser } from 'api/users'
import { getMeetingInfo } from 'api/meetings'
import { getCronofyStatus } from 'api/calendars'
import MySecuritySettingsPage from '../users/MySecuritySettingsPage'
import AppPage from 'cf-components/AppPage'
import { track } from 'userpilot.js'
import { getTenant } from 'api/tenants'
import CustomizedSnackbar from '../../components/CustomizedSnackbar'
import PersonalAlertManagement from './PersonalAlerts/PersonalAlertManagement'
import EmailSubscriptionTable from './EmailSubscriptionsTable'
import EmailSubPopoverOptions from './EmailSubPopperOptions'
import EmailSubscriptionModal from 'library/modals/emailSubscriptionModal/EmailSubscriptionModal'
import { EmailSubscription } from 'classes/emailSubscriptions'
import { MyProfileProvider } from './MyProfileProvider'
import { getOnboardingData, setOnboardingData } from 'api/onboarding'
/* eslint-disable @typescript-eslint/naming-convention */
const useStyles = makeStyles(theme => ({
  paddedTab: {
    padding: 20
  }
}))

const initialMeetingInfoValues = {
  attributes: {
    user_id: '',
    description: '',
    buffer_time: 0,
    location_url: '',
    conferencing_type: 'url',
    meet_now_url: ''
  }
}

function MySettingsPage () {
  const classes = useStyles()
  const [profileDisabled, setProfileDisabled] = useState(true)
  const [deleteModalOpen, setDeleteModalOpen] = useState<number | boolean>(false)
  const [notificationsDisabled, setNotificationsDisabled] = useState(true)
  const [meetingSettingsError, setMeetingSettingError] = useState(false)
  const [conferencingProviderError, setConferenceProviderError] = useState(false)
  const [meetingInfo, setMeetingInfo] = useState(initialMeetingInfoValues)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [state, dispatch] = useReducer(reducer, null)
  const saveNotificationsRef = useRef(null)
  const resetNotificationsRef = useRef(null)
  const { user, reloadUser, isOn } = useContext(SessionContext)
  const userID = user.id
  const [tenant, setTenant] = useState(null)
  const [emailSubAnchorEl, setEmailSubAnchorEl] = useState(null)
  const [emailModalOpen, setEmailModalOpen] = useState<EmailSubscription | null>(null)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const chatServiceUrl = user.links.chat_service

  function reducer (state: any, action: any) {
    if (action.type !== 'initialize') {
      setProfileDisabled(false)
    } else {
      setProfileDisabled(true)
      getOnboardingData('client_onboarding')
        .then(response => {
          if (response) {
            const data = JSON.parse(response.settings)
            data.complete_profile = true
            setOnboardingData('client_onboarding', data)
          }
        }
        )
    }
    switch (action.type) {
      case 'initialize':
        return action.info
      case 'updateInfo':
        return { ...state, [action.name]: action.value }
      case 'updateSocialProfile':
        return {
          ...state,
          social_media_profiles: {
            ...state.social_media_profiles,
            [action.name]: action.value
          }
        }
      case 'updateAgentProfileSettings':
        return {
          ...state,
          agent_profile_settings: {
            ...state.agent_profile_settings,
            [action.name]: action.value
          }
        }
      default:
        throw new Error()
    }
  }

  function saveChanges () {
    saveUser({ userID, state }).then(() => {
      setProfileDisabled(true)
      reloadUser()
    })
    const socialMedias = state?.social_media_profiles
    if (socialMedias) {
      const s = Object.entries(socialMedias).filter(i => i.length && i[1].length)
      if (s.length > 0) {
        track('Social Links Updated')
      }
    }
  }
  function handleDelete (rowID: number) {
    console.log('deleting', rowID)
    setDeleteModalOpen(false)
    // setSnackState({ ...snackState, open: true, message: 'Subscription deleted' })
  }

  useEffect(() => {
    if (user) {
      const tenantID = user.relationships?.tenant?.data?.id
      if (tenantID) {
        getTenant({ tenantID: Number.parseInt(tenantID) })
          .then(response => {
            setTenant(response.data)
          })
      }
    }
  }, [user])

  useEffect(() => {
    getMeetingInfo({ user_id: user.id }).then((response) => {
      if (response.ok) {
        setMeetingInfo(response.data)
        if (response?.data?.attributes?.conferencing_type === 'cronofy') {
          getCronofyStatus().then((response) => {
            if (!response?.data?.attributes?.conferencing_provider_type) {
              setConferenceProviderError(true)
            }
          })
        } else {
          setMeetingSettingError(false)
        }
      } else {
        setMeetingSettingError(true)
      }
    })
  }, [user.id])

  useEffect(() => {
    reloadUser()
  }, [reloadUser])

  const profileProps = {
    disabled: profileDisabled,
    save: saveChanges,
    saveText: 'Save Settings',
    reset: () => dispatch({ type: 'initialize', info: user.attributes })
  }

  const notificationsProps = {
    disabled: notificationsDisabled,
    save: () => saveNotificationsRef.current(),
    saveText: 'Save Notifications',
    reset: () => resetNotificationsRef.current()
  }
  const hasCalendar = isOn(components.CALENDAR)
  const adminAlerts = isOn(components.ACCOUNT_ALERTS) && user.attributes?.perms?.manager
  const tabs: Array<Record<string, any>> = [
    { name: 'Profile', url: '/settings/my_settings/profile', saveProps: profileProps }
  ]
  if (hasCalendar) {
    tabs.push({ name: 'My Calendar', url: '/settings/my_settings/my_calendar' })
  }
  tabs.push({ name: 'Notifications', url: '/settings/my_settings/notifications', saveProps: notificationsProps })
  tabs.push({ name: 'Email Updates', bgColor: 'white', url: '/settings/my_settings/email_updates', action: (e: Event) => setEmailSubAnchorEl(e.target), actionText: 'Create New Email Update' })
  tabs.push({ name: 'Security', url: '/settings/my_settings/security' })
  if (adminAlerts) {
    tabs.push({ name: 'Alerts', url: '/settings/my_settings/alerts' })
  }

  document.title = 'My Settings | Signals'

  return (
    <>
      <AppPage
        title='My Settings'
        tabs={tabs}
        alwaysLoaded={[1]}
      >
        <MyProfileProvider profileDisabled={profileDisabled} saveChanges={saveChanges}>
          <div className={classes.paddedTab}>
            <MyProfile
              chatServiceUrl={chatServiceUrl}
              user={user}
              state={state}
              dispatch={dispatch}
              meetingSettingsError={meetingSettingsError}
            />
          </div>
        </MyProfileProvider>
        {hasCalendar && (
          <div className={classes.paddedTab}>
            <CalendarPage
              setMeetingInfo={setMeetingInfo}
              setMeetingSettingError={setMeetingSettingError}
              conferencingProviderError={conferencingProviderError}
              setConferenceProviderError={setConferenceProviderError}
              meetingInfo={meetingInfo}
              meetingSettingsError={meetingSettingsError}
            />
          </div>
        )}
        <div className={classes.paddedTab}>
          <Notifications
            user={user}
            saveChanges={saveChanges}
            saveNotificationsRef={saveNotificationsRef}
            resetNotificationsRef={resetNotificationsRef}
            setDisabled={setNotificationsDisabled}
            reloadUser={reloadUser}
          />
        </div>
        <EmailSubscriptionTable
          deleteRow={(id: number) => setDeleteModalOpen(id)}
          openModal={(id: number) => setEmailModalOpen(id)}
        />
        <div className={classes.paddedTab}>
          <MySecuritySettingsPage user={user} tenant={tenant} />
        </div>
        {adminAlerts && (
          <div className={classes.paddedTab}>
            <PersonalAlertManagement
              alertModalOpen={alertModalOpen}
              onHide={() => setAlertModalOpen(false)}
            />
          </div>
        )}
      </AppPage>
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
      {deleteModalOpen &&
        <DeleteModal
          open={deleteModalOpen}
          onHide={() => setDeleteModalOpen(false)}
          deleteObject={() => handleDelete(deleteModalOpen)}
          message='Are you sure you want to delete this subscription? This action cannot be undone.'
          title='Delete Subscription'
        />}
      {emailModalOpen &&
        <EmailSubscriptionModal
          emailSubscription={emailModalOpen}
          onClose={() => setEmailModalOpen(null)}
        />}

      {emailSubAnchorEl && (
        <EmailSubPopoverOptions
          anchorEl={emailSubAnchorEl}
          onClose={(emailSub: EmailSubscription | null) => {
            setEmailSubAnchorEl(null)
            setEmailModalOpen(emailSub)
          }}
        />
      )}
    </>
  )
}

export default MySettingsPage
